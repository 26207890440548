import { API_URLS } from "@constants/API_URLS";
import { STATUS_CODE } from "@constants/consts";
import { hgbAxios } from "@utils/axios";
import { LocalDataClass } from "data-class/LocalDataClass";
import { Enterprise } from "services/types/enterprise";

export const getEnterpriseInfo = async (enterpriseId: number | string) => {
    try {
        const enterpriseInfo = (await hgbAxios().get(`${API_URLS.ENTERPRISE_API_URL}/${enterpriseId}?lang=${LocalDataClass.language}`))?.data;
        if (enterpriseInfo.statusCode === STATUS_CODE.success) return enterpriseInfo.result as Enterprise
        return null
    } catch (error) {
        return null
    }
}

export const checkValidCard = async (data: Record<string, string>) => {
    try {
        const checkValidCardData = (await hgbAxios().post(`${API_URLS.FOUR_MEM_ADD_API_URL}?lang=${LocalDataClass.language}`, data))?.data;
        return {
            statusCode: checkValidCardData?.statusCode || '',
            result: checkValidCardData?.result,
            message: checkValidCardData?.message || ''
        }
    } catch (error) {
        return {
            statusCode: '',
            result: null,
            message: ''
        }
    }
}
