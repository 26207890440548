export type A07RequestType = {
  id: string;
  bankId: string;
  bankCode: string;
  bankName: string;
  enterpriseName: string;
  enterpriseNameKana: string;
  representative: string;
  representativeKana: string;
  postalCode: string;
  address1: string;
  address2: string;
  phoneNumber: string;
  startMonthOfYear: string;
  industryCodeMajorClassification: string;
  industryCodeMediumClassification: string;
  paymentType: string;
  hasAccount: string;
  branchNumber: string;
  corporateAccountNumber: string;
  picName: string;
  picNameKana: string;
  picEmail: string;
  agreement: boolean;
  privacyPolicyUrl: string;
  enterpriseCode: string;
  subscriptionType: string;
  expirationPeriodHours: string;
  introducerId: string;
};

type A07LandingPageResponse = {
  id: string;
  enterpriseName: string;
  enterpriseNameKana: string;
  representative: string;
  representativeKana: string;
  postalCode: string;
  address1: string;
  address2: string;
  phoneNumber: string;
  startMonthOfYear: string;
  industryCodeMajorClassification: string;
  industryCodeMediumClassification: string;
  paymentType: string;
  hasAccount: string;
  branchNumber: string;
  corporateAccountNumber: string;
  picName: string;
  picNameKana: string;
  agreement: boolean;
  enterpriseCode: string;
  expirationPeriodHours: string;
};

export type A07CheckValidURLResponse = {
  bankId: string;
  bankCode: string;
  bankName: string;
  privacyPolicyUrl: string;
  picEmail: string;
  enterpriseLandingPage: A07LandingPageResponse;
  isExpired: boolean;
  expirationPeriodHours: string;
};

export type CheckDurationResponseType = A07RequestType;

export type CheckDurationRequestType = {
  registerId: string;
};

type OptionType = {
  value: string;
  name: string;
};

type OptionMethodType = {
  enterpriseMonthlyFree: number;
  enterpriseTrialMonth: number;
  methodType: OptionType[];
}

export type PaymentTypeResponse = OptionType[];
export type PaymentTypeResponseA07 = OptionMethodType;
export type PaymentTypeRequest = {
  enterpriseId?: string;
  registerId?: string;
};

export type HaveNoneTypeResponse = OptionType[];

export type A07_BankResponse = {
  id: string;
  bankName: string;
  privacyPolicyUrl: string;
};
export type A07_BankRequest = {
  bankCode: string;
};

export const A07_DEFAULT_VALUE: A07RequestType = {
  id: '',
  bankId: '',
  bankCode: '',
  bankName: '',
  enterpriseName: '',
  enterpriseNameKana: '',
  representative: '',
  representativeKana: '',
  postalCode: '',
  address1: '',
  address2: '',
  phoneNumber: '',
  startMonthOfYear: '04',
  industryCodeMajorClassification: '',
  industryCodeMediumClassification: '',
  paymentType: '1',
  hasAccount: '',
  branchNumber: '',
  corporateAccountNumber: '',
  picName: '',
  picNameKana: '',
  picEmail: '',
  agreement: false,
  privacyPolicyUrl: '',
  enterpriseCode: '',
  subscriptionType: '',
  expirationPeriodHours: '',
  introducerId: '',
};

export type InsertCardRequest = {
  maskedCardNumber: string;
  id: string;
  email: string;
  kaninId: string;
  kaninPass: string;
};

export type SendEmailBankComplimentaryRequest = {
  id: string;
  email: string;
};

export type CheckValidCardRequestType = {
  transactionDate: string;
  operateId: string;
  bankCode: string;
  kaiinId: string;
  kaiinPass: string;
  token: string;
}