import { HgbCellTooltip } from '@common/contents';
import { LanguageContext } from '@contexts/LanguageContext';
import { cn } from '@utils/cn';
import * as React from 'react';

interface ICategoryItemProps {
  title: string;
  subTitle?: string;
  value?: string;
  subValue?: string;
  className?: string;
  color: string;
  additionValues?: { name: string; value: number }[];
  total?: string;
  totalNonMissionCO2?: number;
}

export const CategoryItem: React.FC<ICategoryItemProps> = React.memo(
  (props) => {
    const {
      text: { common },
    } = React.useContext(LanguageContext)!;
    return (
      <div
        className={cn(
          'tw-relative tw-flex tw-min-h-132 tw-flex-col tw-gap-12 tw-overflow-hidden tw-rounded-12 tw-p-16 tw-pl-24 tw-shadow-md',
          props.className,
        )}
      >
        <div
          className="tw-absolute tw-bottom-0 tw-left-0 tw-top-0 tw-w-6"
          style={{ backgroundColor: props.color }}
        ></div>
        <div className="tw-flex tw-flex-col tw-gap-4">
          <h3 className="tw-font-bold">{props.title}</h3>
          {props.subTitle ? <p>{props.subTitle}</p> : null}
        </div>
        {props.additionValues?.length ? (
          <div>
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-20">
              <p>{props.additionValues[0].name}</p>{' '}
              <HgbCellTooltip>
                <p>{props.additionValues[0].value} tCO2</p>
              </HgbCellTooltip>
            </div>
            {props.totalNonMissionCO2 !== 0 && (
              <>
                <div className="tw-flex tw-items-center tw-justify-between tw-gap-20">
                  <p className="tw-text-nowrap">
                    {props.additionValues[1].name}
                  </p>{' '}
                  <HgbCellTooltip>
                    <p>{props.additionValues[1].value} t-eqCO2</p>
                  </HgbCellTooltip>
                </div>
                <div className="tw-flex tw-items-center tw-justify-between tw-gap-20">
                  <p>{common.label.total}</p>
                  <HgbCellTooltip>
                    <p>{props.total}</p>
                  </HgbCellTooltip>
                </div>
              </>
            )}
            <div className="tw-flex tw-items-center tw-justify-between">
              <p></p>
              {props.subValue && <small>{props.subValue}</small>}
            </div>
          </div>
        ) : (
          <div className="tw-gap-2 tw-flex tw-flex-col tw-items-end">
            <p className="tw-font-bold"> {props.value}</p>
            {props.subValue ? <small>{props.subValue}</small> : null}
          </div>
        )}
      </div>
    );
  },
);
