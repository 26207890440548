import { AuthenTemplate } from '@layouts/templates';
import * as React from 'react';

interface IMaintenanceProps { }

const Maintenance: React.FC<IMaintenanceProps> = (props) => {
  return (
    <AuthenTemplate>
      <br />
      <p className="tw-text-center tw-font-bold">
        ただいまメンテナンス中です。
      </p>
      <br />
      <p>
        <b>【1/25(土)】システム改修に伴うサービス停止のお知らせ</b> <br />
        平素よりZero-Carbon-System（炭削くん）をご利用いただき、誠にありがとうございます。<br /> <br />
        お知らせが直前となり申し訳ございません。<br />
        以下の日程でシステム改修に伴うメンテナンス作業を実施いたします。<br />
        これに伴い、当該時間帯は本サービスをご利用いただけませんので、あらかじめご了承くださいますようお願い申し上げます。<br />

        <b>【メンテナンス日時】2025年1月25日（土）11:00～20:00</b> <br />
        ※作業状況により終了時刻は前後する場合がございます。<br /> <br />
        お客さまにはご不便をおかけいたしますが、ご理解賜りますようよろしくお願い申し上げます。
      </p>
    </AuthenTemplate>
  );
};

export default Maintenance;
