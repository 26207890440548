import dayjs from 'dayjs';
import { HgbResponsive } from '@common/components';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { Button, Table } from 'antd';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePagin } from '@hooks/usePagin';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { WithKey } from 'types/common';
import {
  useHgbQuery,
  useHgbQueryDownload,
  useHgbQueryWithPaging,
} from 'services/common/query';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  parseToHgbSelectOptions,
} from '@common/forms';
import { FormProvider, useForm } from 'react-hook-form';
import { HgbEmpty } from '@common/antd/HgbEmpty';
import { HgbSpin } from '@common/antd/HgbSpin';
import { PaginationProps } from 'antd/lib';
import {
  billPaymentErrorEn,
  billPaymentErrorJp,
  BillPaymentErrorType,
} from '@constants/consts';
import { useHgbMutationPut } from 'services/common/mutation';
import { useQueryClient } from 'react-query';
import { isEmpty } from 'lodash';
import { saveFileToLocal } from '@utils/file';
import { PortalContext } from '@contexts/PortalContext';
import { cn } from '@utils/cn';
import { useConfirm } from '@hooks/useConfirm';
import { toJPFormat } from '@utils/number';

export interface BillManagementResponse {
  paymentDetailId: number;
  dueAmount: number;
  currentMonthAmount: number;
  enterpriseName: string;
  paymentStatus: { value: string; name: string };
  paymentMonth: string;
  paymentType: { value: string; name: string };
  userAmount: string;
  paymentResponse: string;
  paymentAmount: number;
}

type BillManagementRequest = {
  billingMonth?: string;
  nameSearch?: string;
  sentStatus: string;
} & Partial<PaginationProps>;

type PaymentStatusListBox = {
  value: string;
  name: string;
};

type PaymentStatusResponse = PaymentStatusListBox[];

type PaymentStatusUpdateRequest = {
  billingDetailIds: React.Key[];
  updateStatus: number;
  id: number;
};

type DownloadRequest = {
  billingMonth: string;
};

export const D11: FC = () => {
  const {
    text: { D11, common },
    language,
  } = useContext(LanguageContext)!;

  const { user } = useContext(AuthContext)!;
  const { addMessage } = useContext(PortalContext)!;
  const { paginationProps, resetPaginations, PaginationComponent } = usePagin();
  const queryClient = useQueryClient();
  const { ConfirmModal: ConfirmModalNoCharge, hgbConfirm: hgbConfirmNoCharge } =
    useConfirm();
  const { ConfirmModal, hgbConfirm } = useConfirm();

  const pagingRequestMethods = useForm<BillManagementRequest>({
    defaultValues: {
      billingMonth: dayjs().format('YYYY/MM'),
      sentStatus: '9999',
    },
  });

  useEffect(() => {
    resetPaginations();
  }, [
    pagingRequestMethods.watch('sentStatus'),
    pagingRequestMethods.watch('billingMonth'),
    pagingRequestMethods.watch('nameSearch'),
  ]);

  const groupCompnanyRequest: BillManagementRequest = {
    ...paginationProps,
    sentStatus: pagingRequestMethods.watch('sentStatus'),
    billingMonth: pagingRequestMethods.watch('billingMonth'),
    nameSearch: pagingRequestMethods.watch('nameSearch'),
  };

  const billManagementResponsesQuery = useHgbQueryWithPaging<
    BillManagementResponse[],
    BillManagementRequest
  >(API_URLS.LIST_PAYMENT_BATCH, groupCompnanyRequest, {
    queryKey: [QUERY_KEYS.LIST_PAYMENT_BATCH, language, groupCompnanyRequest],
  });

  const columns: ColumnsType<BillManagementResponse> = [
    {
      title: D11.columns.companyName,
      dataIndex: 'enterpriseName',
      width: 200,
      align: 'left',
      render(value) {
        return <div>{value}</div>;
      },
    },
    {
      title: D11.columns.billMonth,
      dataIndex: 'paymentMonth',
      width: 200,
      align: 'left',
      render(_, record) {
        return (
          <div>
            {record?.paymentMonth
              ? moment(record?.paymentMonth, 'YYYYMM').format('YYYY/MM')
              : '-'}
          </div>
        );
      },
    },
    {
      title: D11.columns.status,
      dataIndex: 'paymentStatus',
      width: 180,
      align: 'left',
      render(value, record) {
        return (
          <div
            className={cn({
              'tw-text-error-7': ['1', '7'].includes(
                record?.paymentStatus.value,
              ),
            })}
          >
            {value.name}
          </div>
        );
      },
    },
    {
      title: D11.columns.paymentMethod,
      dataIndex: 'paymentType',
      width: 180,
      align: 'left',
      render(value) {
        return <div>{value.name}</div>;
      },
    },
    {
      title: D11.columns.numberOfUsers,
      dataIndex: 'userAmount',
      width: 200,
      align: 'left',
      render(value) {
        return <div>{value}</div>;
      },
    },
    {
      title: D11.columns.currentMonthAmount,
      dataIndex: 'currentMonthAmount',
      width: 170,
      align: 'left',
      render(value) {
        return value ? (
          `${toJPFormat(value, 0, false)}
            ${D11.columns.yen}`
        ) : (
          <div>-</div>
        );
      },
    },
    {
      title: D11.columns.amount,
      dataIndex: 'paymentAmount',
      width: 170,
      align: 'left',
      render(value) {
        return value ? (
          `${toJPFormat(value, 0, false)}
            ${D11.columns.yen}`
        ) : (
          <div>-</div>
        );
      },
    },
    {
      title: D11.columns.reason,
      dataIndex: 'paymentResponse',
      width: 150,
      align: 'left',
      render(item, record) {
        const err: BillPaymentErrorType = item;

        return (
          <div>
            {record?.paymentStatus.value === '1'
              ? language === 'en'
                ? billPaymentErrorEn[err] + `(${record?.paymentResponse})`
                : billPaymentErrorJp[err] + `(${record?.paymentResponse})`
              : ''}
          </div>
        );
      },
    },
  ];

  const paymentStatusQuery = useHgbQuery<PaymentStatusResponse>(
    API_URLS.PAYMENT_STATUS_BATCH,
    undefined,
    {
      queryKey: [API_URLS.PAYMENT_STATUS_BATCH, language],
      enabled: true,
    },
  );

  const updatePaymentStatus = useHgbMutationPut<
    unknown,
    PaymentStatusUpdateRequest
  >(API_URLS.UPDATE_PAYMENT_BATCH, {
    onSuccess: () => {
      setSelectedRowKeys([]);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.LIST_PAYMENT_BATCH],
      });
    },
    isAlert: true,
  });

  const updatePaymentOptions = useMemo(
    () => parseToHgbSelectOptions(paymentStatusQuery?.data?.result),
    [paymentStatusQuery?.data?.result],
  );

  const rows: WithKey<BillManagementResponse>[] = useMemo(() => {
    return (billManagementResponsesQuery?.data?.result?.content ?? []).map(
      (item) => ({
        ...item,
        key: item.paymentDetailId,
      }),
    );
  }, [billManagementResponsesQuery?.data?.result?.content]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: BillManagementResponse) => {
      return {
        disabled: record.paymentStatus.value !== '4',
      };
    },
  };

  const handleBtnSearch = () => {
    setSelectedRowKeys([]);
    billManagementResponsesQuery.refetch();
  };

  const handleBtnBilling = async (updateStatus: number) => {
    const payload: PaymentStatusUpdateRequest = {
      billingDetailIds: selectedRowKeys,
      updateStatus: updateStatus,
      id: user.bankId,
    };
    if (updateStatus === 3) {
      if (await hgbConfirmNoCharge()) {
        updatePaymentStatus.mutate(payload);
      }
    } else {
      if (await hgbConfirm()) {
        updatePaymentStatus.mutate(payload);
      }
    }
  };

  const downloadReportQuery = useHgbQueryDownload<any, DownloadRequest>(
    API_URLS.DOWNLOAD_PAYMENT,
    {
      billingMonth:
        pagingRequestMethods.watch('billingMonth')?.replace('/', '') || '',
    },
    {
      enabled: false,
    },
  );

  const activeActionButton = useCallback(() => {
    let active = true;
    const currentDay = dayjs().date();
    if (!isEmpty(selectedRowKeys)) active = false;

    // if (currentDay >= 1 && currentDay <= 9 && !isEmpty(selectedRowKeys)) {
    //   active = false;
    // }
    return active;
  }, [selectedRowKeys]);

  const handleBtnExcelOutput = () => {
    downloadReportQuery
      .fetchCustom()
      .then((data: any) => {
        const { file, fileName } = data;
        saveFileToLocal(file, decodeURIComponent(fileName));
      })
      .catch((err) => {
        addMessage('error', err.message);
      });
  };

  return (
    <ManagementTemplate>
      <FormProvider {...pagingRequestMethods}>
        <DataTableTemplate
          title={D11.title}
          inputsComponent={
            <HgbResponsive
              gap
              className="tw-flex tw-flex-col tw-bg-[#EFF7FB] tw-p-16"
            >
              <div className="tw-flex tw-items-end tw-gap-20">
                <HgbDatePicker
                  label={D11.filter.billMonth}
                  picker="month"
                  {...pagingRequestMethods.register('billingMonth')}
                  format="YYYY/MM"
                  className="tw-w-120 tw-cursor-pointer"
                />
                <Button
                  onClick={() => {
                    handleBtnExcelOutput();
                  }}
                  type={'primary'}
                >
                  {D11.button.excelOutput}
                </Button>
              </div>

              <div className="tw-flex tw-flex-wrap tw-items-end tw-justify-between tw-gap-24">
                <div className="tw-flex tw-flex-wrap tw-gap-52">
                  <HgbAntdInput
                    {...pagingRequestMethods.register('nameSearch')}
                    type="K"
                    maxLength={100}
                    search
                    className="tw-w-240 "
                    placeholder={common.placeholder.enterpriseNameSearch}
                    label={common.label.enterpriseId}
                  />

                  <HgbSelect
                    {...pagingRequestMethods.register('sentStatus')}
                    options={updatePaymentOptions}
                    placeholder=""
                    label={D11.filter.status}
                    showSearch
                    onSearch={handleBtnSearch}
                    className="tw-w-220"
                  />
                </div>
                <div className="tw-flex tw-flex-wrap tw-gap-52">
                  {/* <Button
                    onClick={() => {
                      handleBtnSearch();
                    }}
                    type={'primary'}
                  >
                    {D11.button.search}
                  </Button> */}
                  <Button
                    onClick={() => {
                      handleBtnBilling(5);
                    }}
                    type={'primary'}
                    disabled={activeActionButton()}
                  >
                    {D11.button.billingEnforcement}
                  </Button>
                  <Button
                    onClick={() => {
                      handleBtnBilling(3);
                    }}
                    type={'primary'}
                    disabled={activeActionButton()}
                  >
                    {D11.button.noCharge}
                  </Button>
                </div>
              </div>
            </HgbResponsive>
          }
          paginationComponents={
            <PaginationComponent
              current={billManagementResponsesQuery?.data?.result?.currentPage}
              total={billManagementResponsesQuery?.data?.result?.totalRecord}
              pageSize={billManagementResponsesQuery?.data?.result?.pageSize}
            />
          }
        >
          {rows.length > 0 ? (
            <Table
              rowSelection={{ ...(rowSelection as any) }}
              columns={columns}
              dataSource={rows}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
          ) : null}
          {billManagementResponsesQuery.isLoading ? (
            <HgbSpin placement="middle" />
          ) : null}

          {billManagementResponsesQuery.isFetched && rows.length === 0 ? (
            <HgbEmpty description={common.message.noResult} />
          ) : null}
        </DataTableTemplate>
      </FormProvider>
      <ConfirmModal
        title={D11.confirmModalBillingEnforcement.title}
        okText={D11.confirmModalBillingEnforcement.okText}
        cancelText={common.button.cancel}
      >
        {D11.confirmModalBillingEnforcement.content}
      </ConfirmModal>
      <ConfirmModalNoCharge
        title={D11.confirmModalNoCharge.title}
        okText={D11.confirmModalNoCharge.okText}
        cancelText={common.button.cancel}
      >
        {D11.confirmModalNoCharge.content}
      </ConfirmModalNoCharge>
    </ManagementTemplate>
  );
};
