import { HgbEmpty } from '@common/antd/HgbEmpty';
import { HgbSpin } from '@common/antd/HgbSpin';
import { HgbResponsive } from '@common/components';
import { API_URLS } from '@constants/API_URLS';
import { STATUS_CODE } from '@constants/consts';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { useContext, useState } from 'react';
import { useHgbQuery } from 'services/common/query';

export const A07Term: React.FunctionComponent<{ bankId: string }> = ({
  bankId,
}) => {
  const [termsInfo, setTermsInfo] = useState<string>('');
  const { addMessage } = useContext(PortalContext)!;
  const {
    text: { E0018, common },
  } = useContext(LanguageContext)!;

  const termInfo = useHgbQuery<
    { bankId: number; termsOfUse: string },
    { bankId: string }
  >(
    API_URLS.TERMS,
    {
      bankId: bankId,
    },
    {
      onSuccess: (data) => {
        if (data?.result) {
          if (data.statusCode === STATUS_CODE.success) {
            setTermsInfo(data?.result.termsOfUse);
          }
        }
        if (data.statusCode === STATUS_CODE.notFound) {
          addMessage('error', E0018);
        }
      },
      queryKey: [API_URLS.TERMS, bankId],
    },
  );

  return (
    <HgbResponsive
      gap
      className="tw-grid tw-w-full tw-flex-auto tw-grid-cols-1 tw-items-start tw-self-start tw-rounded-16 tw-bg-white tw-p-16 pc:!tw-gap-16 pc:tw-p-24"
    >
      {termsInfo !== '' ? (
        <div className="tw-max-h-[60vh] tw-overflow-y-scroll">
          <div
            className="tw-w-full"
            dangerouslySetInnerHTML={{
              __html: termsInfo,
            }}
          ></div>
        </div>
      ) : null}
      {termInfo.isLoading ? <HgbSpin placement="middle" /> : null}

      {termInfo.isFetched && termsInfo === '' ? (
        <HgbEmpty description={common.message.noResult} />
      ) : null}
    </HgbResponsive>
  );
};
