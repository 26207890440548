import { cn } from '@utils/cn';
import { Button, ButtonProps } from 'antd';
import { debounce } from 'lodash';
import * as React from 'react';

interface HgbButtonProps extends ButtonProps {
  endIcon?: React.ReactNode;
}

export const HgbButton = React.forwardRef<
  HTMLButtonElement,
  HgbButtonProps
>(({ endIcon, className = '', onClick, children, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      type="default"
      size="large"
      shape="round"
      className={cn(
        'tw-flex tw-items-center tw-justify-center tw-gap-4 [&_.ant-btn-icon]:!tw-mr-0',
        {
          'tw-relative !tw-pt-0 !tw-pb-0': endIcon,
          'tw-h-44 tw-min-w-[120px]': props.size === 'small',
          'tw-pointer-events-none': props.loading
        },
        className,
      )}
      {...props}
      onClick={endIcon ? undefined : onClick}
    >
      <span
        className={cn(
          'tw-flex-auto tw-text-inherit',
          {
            'tw-underline tw-underline-offset-4': props.type === 'link',
            '!tw-flex tw-h-full tw-items-center tw-justify-center tw-pl-16 tw-pr-32': endIcon,
          },
          className,
        )}
        onClick={endIcon ? onClick : undefined}
        style={{ textDecorationSkipInk: 'none' }}
      >
        {children}
      </span>
      {endIcon && (
        <div className="tw-absolute tw-right-8 tw-z-10 tw-shrink-0">
          {endIcon}
        </div>
      )}
    </Button>
  );
});

type Button2Props = React.HTMLProps<HTMLButtonElement>
type HgbButton2Props = Omit<Button2Props, 'type'> & {
  endIcon?: React.ReactNode,
  type?: 'outline' | 'filled',
  loading?: boolean;
  htmlType?: "button" | "submit" | "reset",
  debounceTime?: number
}

export const createLinkButtonClass = (props?: HgbButton2Props) =>
  cn(
    'tw-cursor-pointer tw-text-[14px] tw-font-bold tw-underline-offset-4 tw-text-[#1677ff] visited:tw-text-[#1677ff] hover:tw-text-[#69b1ff] active:tw-text-[#0958d9] disabled:tw-cursor-not-allowed disabled:tw-text-neutral-3',
    { 'tw-pointer-events-none': props?.loading },
  );

export const HgbButton2 = React.forwardRef<HTMLButtonElement, HgbButton2Props>(({
  className, onClick, children, endIcon,
  type = 'filled', loading, htmlType,
  debounceTime = 300,
  ...props }, ref) => {
  const [isHoverEndIcon, setIsHoverEndIcon] = React.useState(false);

  const onClickDebounce = React.useCallback(
    debounce((e: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(e)
    }, debounceTime), [debounceTime])

  const handleMouseEnterEndIcon = React.useCallback(() => {
    setIsHoverEndIcon(true)
  }, [])

  const handleMouseLeaveEndIcon = React.useCallback(() => {
    setIsHoverEndIcon(false)
  }, [])

  const handleClickEndIcon = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, [])

  return <button
    ref={ref}
    className={
      cn('tw-min-h-40 tw-py-4 disabled:tw-cursor-not-allowed tw-px-16 tw-rounded-full tw-overflow-hidden tw-cursor-pointer tw-relative tw-text-[14px]',
        { 'disabled:tw-bg-neutral-1/10 disabled:tw-border-neutral-1/40 disabled:tw-text-neutral-1/40 disabled:tw-border-solid disabled:tw-border': type === 'filled' },
        { 'tw-bg-primary-5 tw-text-white': type === 'filled' },
        { 'tw-text-primary-5 tw-border-primary-5 tw-bg-white tw-border tw-border-solid': type === 'outline' },
        { 'active:tw-text-primary-6 active:tw-border-primary-6': !isHoverEndIcon && type === 'outline' },
        { 'active:tw-bg-primary-6': !isHoverEndIcon && type === 'filled' },
        { 'hover:tw-opacity-80 active:tw-opacity-100': !isHoverEndIcon },
        { 'tw-pointer-events-none': loading },
        className)
    }
    onClick={onClickDebounce}
    type={htmlType}
    {...props}
  >
    {children}
    {endIcon &&
      <div className='tw-absolute tw-right-8 tw-top-1/2 -tw-translate-y-1/2'
        onClick={handleClickEndIcon}
        onMouseEnter={handleMouseEnterEndIcon}
        onMouseLeave={handleMouseLeaveEndIcon}
      >{endIcon}
      </div>
    }
  </button>
})