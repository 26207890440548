import { AxiosResponseType } from '@hooks/useApi';
import { hgbAxios } from '@utils/axios';
import { LocalDataClass } from 'data-class/LocalDataClass';
import { API_URLS } from './API_URLS';

export const REACT_APP_ENV = (process.env.REACT_APP_ENV?.trim() || '') as
  | 'development'
  | 'staging'
  | 'production'
  | '';
export const PUBLIC_URL = process.env.PUBLIC_URL?.trim() || '';
export const REACT_APP_BASE_NAME =
  process.env.REACT_APP_BASE_NAME?.trim() || '';
export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL?.trim() || '';
export const REACT_APP_UPLOAD_OCR_URL =
  process.env.REACT_APP_UPLOAD_OCR_URL?.trim() || '';
export const REACT_APP_E_SCOTT_URL =
  process.env.REACT_APP_E_SCOTT_URL?.trim() || '';

export const KAIN_ID_ADD = '4MemAdd';
export const KAIN_ID_CHANGE = '4MemChg';
export const HIGO_QUATRICS_URL =
  'https://higobank.syd1.qualtrics.com/jfe/form/SV_2mDdjQ7G1QGyQWG';

export const LANG_KEY = 'i18nextLng';
export const STATUS_CODE = {
  mustChangePassword: 10003,
  shouldChangePassword: 10015,
  tokenExpired: 10110,
  invalidToken: 10109,
  success: 10000,
  notFound: 10104,
  registerSuccess: 10011,
  deleteSuccess: 10012,
  updateSuccess: 10013,
  productNotExit: 10244,
  orgBaseNotExit: 10246,
  orgBaseAllocation: 10251,
  dataNotExit: 10241,
  dataNotExitEmission: 10256,
  scopeEmissionsChanged: 10250,
  paymentIssue: 10261,
  invalidIP: 10268,
  invalidBatch04: 10208,
  logoutInvalidIP: 10269,
};

export const ROLES = {
  ROLE_PRESIDING_BANK: 'ROLE_PRESIDING_BANK',
  ROLE_MANAGEMENT_BANK: 'ROLE_MANAGEMENT_BANK',
  ROLE_ENTERPRISE_ADMIN: 'ROLE_ENTERPRISE_ADMIN',
  ROLE_ENTERPRISE_USER: 'ROLE_ENTERPRISE_USER',
} as const;

export type Role = keyof typeof ROLES;

export const SESSION_TIME = 3600000;

export const USER_LOCAL_STORAGE_NAME: `${'USER_LOCAL'}${string}` = `${'USER_LOCAL'}${REACT_APP_ENV}`;

export const MY_SECRET_KEY = 'HIGOBANK_2023';

export const paymentError = {
  OK: `ProcessId/ProcessPass が発行されます。`,
  K01: `当該 OperateId の設定値を網羅しておりません。（送信項目不足、または項目エラー）設定値をご確認の上、再処理を行ってください。`,
  K02: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K03: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K04: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K05: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K06: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K07: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K08: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K09: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K10: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K11: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K12: `Master  電文で発行された「ProcessId」または「ProcessPass」では無いことを意味します。
設定値をご確認の上、再処理を行ってください。`,
  K14: `要求された Process 電文の「OperateId」が要求対象外です。
例：「1Delete：取消」に対して再度「1Delete：取消」を送信したなど。`,
  K15: `返戻対象となる会員の数が、最大件数（30 件）を超えました。`,
  K20: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K21: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K22: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K24: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K25: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K26: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K27: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K28: `半角数字 4 桁の設定値であることをご確認の上、再処理を行ってください。`,
  K30: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K31: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K32: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K33: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K34: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K35: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K36: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K37: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K39: `YYYYMMDD 形式では無い、または未来日付であることを意味します。設定値をご確
認の上、再処理を行ってください。`,
  K40: `取引は OK 判定でしたが McSecCd が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  K41: `取引は OK 判定でしたが McBirthDay が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  K42: `取引は OK 判定でしたが McTelNo が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  K43: `取引は OK 判定でしたが McKanaSei が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  K44: `取引は OK 判定でしたが McKanaMei が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  K45: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K46: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K47: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K48: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K62: `形式エラーです。設定値をご確認の上、再処理を行ってください。
※画面アップロード、ファイル伝送時のみ応答されます。`,
  K63: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K64: `形式エラーです。設定値をご確認の上、再処理を行ってください。
※画面アップロード、ファイル伝送時のみ応答されます。`,
  K65: `形式エラーです。設定値をご確認の上、再処理を行ってください。
※画面アップロード、ファイル伝送時のみ応答されます。`,
  K66: `形式エラーです。設定値をご確認の上、再処理を行ってください。
※画面アップロード、ファイル伝送時のみ応答されます。`,
  K67: `形式エラーです。設定値をご確認の上、再処理を行ってください。
※カード決済  Master  データファイル大量データ処理をご利用の場合のみ応答されます。`,
  K68: `会員登録機能が未設定となっております。`,
  K69: `この会員 ID はすでに使用されています。`,
  K70: `会員削除電文に対して会員が無効状態ではありません`,
  K71: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K73: `会員無効解除電文に対して会員が既に有効となっています`,
  K74: `会員認証に連続して失敗し、ロックアウトされました。`,
  K75: `会員は有効ではありません。`,
  K79: `現在はカード無効、Login回数無効または会員無効状態です。`,
  K80: `Master 電文は会員 ID が設定されています。Process 電文も会員 ID を設定してください。`,
  K81: `Master 電文は会員 ID が未設定です。Process 電文の会員 ID も未設定としてください。`,
  K82: `カード番号がセットされておりません。`,
  K83: `カード有効期限がセットされておりません。`,
  K84: `会員 ID が適切ではありません。`,
  K85: `会員パスワードが適切ではありません。`,
  K86: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K87: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K88: `取引の対象が複数件存在します。弊社までお問い合わせください。`,
  K89: `この処理番号は既に使用されています。`,
  K95: `ファイルフォーマットが異なります。
※ファイル伝送時のみ応答されます。`,
  K96: `障害報が通知されている場合は、回復報を待って再処理を行ってください。
その他は、弊社までお問い合わせください。`,
  K98: `障害報が通知されている場合は、回復報を待って再処理を行ってください。
その他は、弊社までお問い合わせください。`,
  K99: `弊社までお問い合わせください。`,
  KBX: `重複した電文を受信しました。
利用者によりブラウザバックや二重押下が行われた可能性があります。`,
  KBY: `元取引が完了していません。取引状態をご確認ください。`,
  KBZ: `元取引が存在しません。取引状態をご確認ください。`,
  KE0: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KE1: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KE2: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KE3: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KE4: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KE5: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KE6: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KEA: `取引は OK 判定でしたが McSex が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  KEB: `取引は OK 判定でしたが McBirthYear が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  KEC: `取引は OK 判定でしたが McPostal が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  KED: `取引は OK 判定でしたが McAcntNo1 が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  KEE: `取引は OK 判定でしたが McAcntNo2 が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  KEF: `取引は OK 判定でしたが McCardMei が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  KG8: `マーチャント  ID、マーチャントパスワード認証に連続して失敗し、ロックアウトされました。`,
  KGH: `会員参照の利用は制限されています。`,
  KHW: `セッションタイムアウトが発生しました。`,
  KHX: `形式エラー。設定値を確認の上、再処理を行ってください。※トークン取引で使用`,
  KHZ: `利用可能なトークンがありません。※トークン取引で使用`,
  KI1: `形式エラー。設定値を確認の上、再処理を行ってください。※トークン取引で使用`,
  KI2: `すでに利用されたトークンです。※トークン取引で使用`,
  KI3: `トークンの有効期限が切れています。※トークン取引で使用`,
  KI4: `形式エラー。設定値を確認の上、再処理を行ってください。※トークン取引で使用`,
  KI5: `同一カード番号の連続入力によりロックされています。※トークン取引で使用`,
  KI6: `同一端末からの連続入力により端末がロックされています。※トークン取引で使用`,
  KI8: `取引の対象が複数件存在します。※トークン取引で使用`,
  KI9: `形式エラー。設定値を確認の上、再処理を行ってください。`,
  KIA: `形式エラー。設定値を確認の上、再処理を行ってください。`,
  KIB: `形式エラー。設定値を確認の上、再処理を行ってください。`,
  KIE: `形式エラー。設定値を確認の上、再処理を行ってください。`,
  KIF: `オブジェクト識別子が含まれていません。設定値を確認の上、再処理を行ってください。`,
  KIH: `署名時間と取引日時の差が60秒を超えています。（攻撃の可能性があります）※テスト環境では発生しません。`,
  KIJ: `トークンとプロセスIDが設定されています、いずれかを設定ください。`,
  KIK: `元取引がApplePay取引ではありません。ApplePay会員登録・会員更新時にクレジットカード決済のプロセスIDで更新した場合に発生いたします。`,
  KIM: `取引の対象が複数件存在します。※トークン取引で使用`,
  KIN: `売上障害取消時に内部で既に取り消された場合に発生いたします。`,
  KIR: `形式エラー。設定値を確認の上、再処理を行ってください。`,
  KIS: `オーソリ・会員同時登録は出来ません。`,
  KIW: `形式エラー。設定値を確認の上、再処理を行ってください。`,
  KIU: `会員IDの連続入力によりロックされています。`,
  C01: `システムエラーが発生しました。お手数ですが、炭削くん事務局までお問い合わせください。`,
  C02: `システムエラーが発生しました。お手数ですが、炭削くん事務局までお問い合わせください。`,
  C03: `システムエラーが発生しました。お手数ですが、炭削くん事務局までお問い合わせください。`,
  C10: `ご契約のある支払回数(区分）をセットし再処理を行ってください。`,
  C11: `ボーナス払いご利用対象外期間のため、支払区分を変更して再処理を行
ってください。`,
  C12: `ご契約のある分割回数(区分）をセットし再処理を行ってください。`,
  C13: `カード有効期限の年月入力相違、または、有効期限切れカードです。`,
  C14: `取消処理が既に行われております。管理画面で処理状況をご確認ください。`,
  C15: `ボーナス払いの下限金額未満によるエラーのため、支払方法を変更して再
処理を行ってください。`,
  C16: `カード番号が正しくありません。`,
  C17: `カード番号体系が正しくありません。`,
  C18: `オーソリ除外となるカード番号。本エラーを発生するには個別に設定が必
要になります。弊社までお問い合わせください。`,
  C70: `システムエラーが発生しました。お手数ですが、炭削くん事務局までお問い合わせください。`,
  C71: `貴社送信内容が仕様に沿っているかご確認の上、弊社までお問い合わせ
ください。`,
  C80: `システムエラーが発生しました。お手数ですが、炭削くん事務局までお問い合わせください。`,
  C82: `カード会社システムの停止を意味します。`,
  C98: `システムエラーが発生しました。お手数ですが、炭削くん事務局までお問い合わせください。`,
  C99: `貴社送信内容が仕様に沿っているかご確認の上、弊社までお問い合わせ
ください。`,
  G12: `このカードでは取引をする事ができません。`,
  G22: `このカードでは取引をする事ができません。`,
  G30: `取引中にエラーが発生しております。後ほどお試しください。`,
  G42: `暗証番号が正しくありません。
※デビットカードの場合、発生する場合があります。`,
  G44: `入力されたセキュリティコードが正しくありません。`,
  G45: `セキュリティコードが入力されていません。`,
  G54: `1 日利用回数または金額オーバーです。`,
  G55: `1日利用回数または金額オーバーです。`,
  G56: `クレジットカードが無効です。`,
  G60: `クレジットカードが無効です。`,
  G61: `無効カードが入力されました。有効カードでお試しください。`,
  G65: `カード番号が正しくありません。`,
  G68: `金額が正しくありません。`,
  G72: `ボーナス金額が正しくありません。`,
  G74: `分割回数の入力が正しくありません。`,
  G75: `分割払いの下限金額を下回っています。`,
  G78: `支払方法の入力が正しくありません。`,
  G83: `有効期限の入力が正しくありません。`,
  G84: `承認番号の入力が正しくありません。`,
  G85: `このカードでは取引をする事ができません。`,
  G92: `このカードでは取引をする事ができません。`,
  G94: `このカードでは取引をする事ができません。`,
  G95: `このカードでは取引をする事ができません。`,
  G96: `このカードでは取引をする事ができません。`,
  G97: `このカードでは取引をする事ができません。`,
  G98: `このカードでは取引をする事ができません。`,
  G99: `このカードでは取引をする事ができません。`,
  U01: `エンドユーザーの3Dセキュアパスワードが未設定です。`,
  U02: `3Dセキュア未対応カード発行会社です。`,
  U03: `取引は正常終了しましたが、ネットワーク等でエラーが発生し3D認証ができませんでした。`,
  U04: `3Dセキュア認証ができませんでした。各カード会社の基準により3Dセキュア認証画面を表示せずにNGとなる場合がございます。`,
  U05: `認証システムで改ざんをチェックし3Dセキュア認証ができませんでした。`,
  U06: `「登録確認」と「認証結果確認」との間が40分以上経過しています。`,
  U07: `認証システムにて電文処理中に同じ電文を受信しました。`,
  U08: `退避したセッション情報が削除されていた`,
  U09: `3Dセキュア未対応ブランドです。`,
  U10: `ブランドのサーバーが停止、または接続不可により3Dセキュア認証ができませんでした。`,
  U11: `必須項目のインターフェイスが無い、カード番号所定の桁数範囲外など`,
  U12: `弊社までお問い合せください。`,
  U13: `弊社までお問い合せください。`,
  U14: `計画停止案内が通知されている場合は、計画停止終了を待って再処理を行ってください。その他は、弊社までお問い合わせください。`,
  U95: `弊社システム内部エラー。`,
  U96: `障害報が通知されている場合は、回復報を待って再処理を行ってください。その他は弊社までお問い合せください。`,
  U99: `弊社までお問い合せください。`,
  K23: `半角数字ではないことまたは、利用額変更で元取引と利用金額が同一となっていることを意味します。8 桁以下(0  以外)の半角数字であること、利用額変更で元取引と
利用金額が同一でないことをご確認の上、再処理を行ってください。`,
  KIL: `元取引が通常取引ではありません。クレジットカード決済の会員登録・会員更新時にApple Pay決済のプロセスIDで更新した場合、または、Apple Pay会員に対して、会員更新時にカード番号、カード有効期限を設定して更新した場合に発生いたします。`,
};

export type PaymentErrorType = keyof typeof paymentError;
export type BillPaymentErrorType = keyof typeof billPaymentErrorEn;

export const billPaymentErrorEn = {
  OK: 'Request approved',
  K04: 'Item 「TenantId」 format error',
  K05: 'Item 「TransactionDate」 format error',
  K06: 'Item 「OperateId」 format error',
  K07: 'Item 「MerchantFree1」 format error',
  K08: 'Item 「MerchantFree2」 format error',
  K09: 'Item 「MerchantFree3」 format error',
  K10: 'Item 「ProcessId」 format error',
  K11: 'Item 「ProcessPass」 format error',
  K20: 'Item 「CardNo」 format error',
  K21: 'Item 「CardExp」 format error',
  K22: 'Item 「PayType」 format error',
  K23: 'Item 「Amount」 format error',
  K24: 'Item 「SecCd」 format error',
  K25: 'Item 「KanaSei」 format error',
  K26: 'Item 「KanaMei」 format error',
  K27: 'Item 「BirthDay」 format error',
  K28: 'Item 「TelNo」 format error',
  K30: 'Item 「MessageVersionNo3D」 format error',
  K32: 'Item 「EncordXId3D」 format error',
  K33: 'Item 「TransactionStatus3D」 format error',
  K34: 'Item 「CAVVAlgorithm3D」 format error',
  K35: 'Item 「CAVV3D」 Format error',
  K36: 'Item 「ECI3D」 format error',
  K37: 'Item 「PANCard3D」 format error',
  K40: 'Certification Assist (CA) service item Security Code Unmatch Verification NG',
  K41: 'CA Service Item Birth month/year Verification Unmatch  NG',
  K42: 'CA Service Item Last 4 digits of telephone number verification NG',
  K43: 'CA Service Item Kana Name(Last Name) Unmatch verification  NG',
  K44: 'CA Service Item Kana name (First Name) Unmatch Verification NG',
  K45: 'Item 「KaiinId」 format error',
  K46: 'Item 「KaiinPass」 format error',
  K71: 'Certification error of member ID',
  K74: 'Locked out due to continuous error with respect to member certification',
  K79: 'Member judgement error ( Invalid card/Invalid Login Frequency/Invaild Member)',
  K82: 'Fraudulent input details of card number',
  K83: 'Fraudulent input details of card expirely',
  K84: 'Fraudulent input details of member ID',
  K85: 'Fraudulent input details of member password',
  K98: 'e-SCOTT Smart internal minor failure occurrence',
  KE0: 'Item 「Sex」 format error',
  KE1: 'Item 「BirthYear」 format error',
  KE2: 'Item 「Postal」 format error',
  KE3: 'Item 「AcntNo1」 format error',
  KE4: 'Item 「AcntNo2」 format error',
  KE5: 'Item 「CardMei」 format error',
  KEA: 'CA service item gender unmatch verified  NG',
  KEB: 'CA service item Bbirthyear unmatch verified NG',
  KEC: 'CA service item postal code unmatch verified NG',
  KED: 'CA  service item First 4 digits of bank account number unmatch verified NG',
  KEE: 'CA service item last 4digits of bank account verified unmatch NG',
  KEF: 'CA service Item Card Name unmatch verified NG',
  KIR: 'Item 「AuthKaiinAddFlg」 format error',
  KIW: 'Item 「KaiinIdAutoRiyoFlg」 format error',
  KNZ: 'Item 「DSTransactionId」 format error',
  KOA: 'Item 「ThreeDSServerTransactionId」 format error',

  C01: "SPS's set up related error",
  C02: 'e-SCOTT system error',
  C03: 'e-SCOTT transmission error',
  C10: 'Payment category error',
  C11: 'Bonus out of term error',
  C12: 'Installment frequency error',
  C13: 'Expirely error',
  C15: 'Bonus amount floor limit error',
  C16: 'Card Number error',
  C17: 'Card number system error',
  C18: 'Authorization excluded card number system error',
  C70: "SPS's set up related error",
  C71: "SPS's set up related error",
  C80: 'Card company center closed',
  C82: 'Transaction ID duplication error ',
  C98: 'Other extraordinary error',
  C99: 'Other extraordinary error',
  G12: 'Card usage prohibited',
  G22: 'Payment perpetually prohibited',
  G30: 'Transaction judgement reserved',
  G42: 'ID error',
  G44: 'Wrong security code',
  G45: 'No input of security code',
  G54: 'Use frequency error',
  G55: 'Limit amount exceeded',
  G56: 'Invalid card',
  G60: 'Accident error',
  G61: 'Invalid Card',
  G65: 'Card number error',
  G68: 'Amount error',
  G72: 'Bonus amount error',
  G74: 'Installment frequency error',
  G75: 'Installment amount error',
  G78: 'Payment category error',
  G83: 'Validity error',
  G84: 'Approval number error',
  G85: 'CAFIS Agent error',
  G92: 'Card company arbitrary error',
  G94: 'Cycle sequence number error',
  G95: 'Online business closed',
  G96: 'Accident card input error',
  G97: 'Request rejected',
  G98: 'Error related to a business which does not belong to the card company.',
  G99: 'Rejected connection request',
  TS00: 'Exception Error',
};

export const billPaymentErrorJp = {
  OK: '許可応答',
  K04: '項目「TenantId」精査エラー。',
  K05: '項目「TransactionDate」精査エラー。',
  K06: '項目「OperateId」精査エラー。',
  K07: '項目「MerchantFree1」精査エラー。',
  K08: '項目「MerchantFree2」精査エラー。',
  K09: '項目「MerchantFree3」精査エラー。',
  K10: '項目「ProcessId」精査エラー。',
  K11: '項目「ProcessPass」精査エラー。',
  K20: '項目「CardNo」精査エラー。',
  K21: '項目「CardExp」精査エラー。',
  K22: '項目「PayType」精査エラー。',
  K23: '項目「Amount」精査エラー。',
  K24: '項目「SecCd」精査エラー。',
  K25: '項目「KanaSei」精査エラー。',
  K26: '項目「KanaMei」精査エラー。',
  K27: '項目「BirthDay」精査エラー。',
  K28: '項目「TelNo」精査エラー。',
  K30: '項目「MessageVersionNo3D」精査エラー。',
  K32: '項目「EncordXId3D」精査エラー。',
  K33: '項目「TransactionStatus3D」精査エラー。',
  K34: '項目「CAVVAlgorithm3D」精査エラー。',
  K35: '項目「CAVV3D」精査エラー。',
  K36: '項目「ECI3D」精査エラー。',
  K37: '項目「PANCard3D」精査エラー。',
  K40: '認証アシストサービス項目セキュリティコードアンマッチ検証 NG。',
  K41: '認証アシストサービス項目生月日アンマッチ検証 NG。',
  K42: '認証アシストサービス項目電話番号下 4 桁アンマッチ検証 NG。',
  K43: '認証アシストサービスカナ氏名（姓）アンマッチ検証 NG。',
  K44: '認証アシストサービス項目カナ氏名（名）アンマッチ検証 NG。',
  K45: '項目「KaiinId」精査エラー。',
  K46: '項目「KaiinPass」精査エラー。',
  K71: '会員 ID の認証エラー。',
  K74: '会員認証に連続して失敗し、ロックアウトされました。',
  K79: '会員判定エラー(カード無効、Login回数無効または会員無効)。',
  K82: 'カード番号の入力内容不正。',
  K83: 'カード有効期限の入力内容不正。',
  K84: '会員 ID の入力内容不正。',
  K85: '会員パスワードの入力内容不正。',
  K98: '本システム内部で軽度障害が発生。',
  KE0: '項目「Sex」精査エラー。',
  KE1: '項目「BirthYear」精査エラー。',
  KE2: '項目「Postal」精査エラー。',
  KE3: '項目「AcntNo1」精査エラー。',
  KE4: '項目「AcntNo2」精査エラー。',
  KE5: '項目「CardMei」精査エラー。',
  KEA: '認証アシストサービス項目性別アンマッチ検証 NG。',
  KEB: '認証アシストサービス項目生年月日の年アンマッチ検証 NG。',
  KEC: '認証アシストサービス項目郵便番号アンマッチ検証 NG。',
  KED: '認証アシストサービス項目銀行口座番号上 4 桁アンマッチ検証 NG。',
  KEE: '認証アシストサービス項目銀行口座番号下 4 桁アンマッチ検証 NG。',
  KEF: '認証アシストサービス項目カード名義アンマッチ検証 NG。',
  KIR: '項目「AuthKaiinAddFlg」精査エラー。',
  KIW: '項目「KaiinIdAutoRiyoFlg」精査エラー。',
  KNZ: '項目「DSTransactionId」精査エラー。',
  KOA: '項目「ThreeDSServerTransactionId」精査エラー。',
  C01: '弊社設定関連エラー。',
  C02: 'e-SCOTT システムエラー。',
  C03: 'e-SCOTT 通信エラー。',
  C10: '支払区分エラー。',
  C11: 'ボーナス期間外エラー',
  C12: '分割回数エラー。',
  C13: '有効期限切れエラー。',
  C15: 'ボーナス金額下限エラー。',
  C16: 'カード番号エラー。',
  C17: 'カード番号体系エラー。',
  C18: 'オーソリ除外対象のカード番号体系エラー。',
  C70: '弊社設定情報エラー',
  C71: '弊社設定情報エラー',
  C80: 'カード会社センター閉局',
  C82: '取引識別番号重複エラー ',
  C98: 'その他例外エラー',
  C99: 'その他例外エラー',
  G12: 'カード使用不可',
  G22: '支払永久禁止',
  G30: '取引判定保留',
  G42: '暗証番号エラー',
  G44: 'セキュリティコード誤り',
  G45: 'セキュリティコード入力無',
  G54: '利用回数エラー',
  G55: '限度額オーバー',
  G56: '無効カード',
  G60: '事故カード',
  G61: '無効カード',
  G65: 'カード番号エラー',
  G68: '金額エラー',
  G72: 'ボーナス額エラー',
  G74: '分割回数エラー',
  G75: '分割金額エラー',
  G78: '支払区分エラー',
  G83: '有効期限エラー',
  G84: '承認番号エラー',
  G85: 'CAFIS 代行エラー',
  G92: 'カード会社任意エラー',
  G94: 'サイクル通番エラー',
  G95: '当該業務オンライン終了',
  G96: '事故ｶｰﾄﾞデータエラー',
  G97: '当該要求拒否',
  G98: '当該自社対象業務エラー',
  G99: '接続要求自社受付拒否',
  TS00: '例外エラー。',
};
