import {
  EyeInvisibleOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
// import exactmath from 'exact-math';
import { HgbFieldError } from '@common/contents/HgbFieldError';
import HgbInfoLink from '@common/contents/HgbInfoLink';
import HgbInfoTooltip from '@common/contents/HgbInfoTooltip';
import { cn } from '@utils/cn';
import { isFullSize, isHalfSize, toRoundInput } from '@utils/input.utils';
import { Input, InputProps, InputRef } from 'antd';
import { RenderFunction, TooltipPlacement } from 'antd/lib/tooltip';
import { isEmpty, isNaN } from 'lodash';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

// eslint-disable-next-line
const integerRegex = /^-?\d*$/;
// eslint-disable-next-line
const positiveIntegerRegex = /^\d*$/;
// eslint-disable-next-line
const decimalRegex = /^-?\d*\.?\d*$/;
// eslint-disable-next-line
const positiveDecimalRegex = /^\d*\.?\d*$/;
// eslint-disable-next-line
const emailRegex = /^[A-Za-z0-9@.+-_]*$/g;
// eslint-disable-next-line
const passwordRegex = /([A-Z][a-z][0-9][~!@#$%^&*()_+`{}])\w*$/g;

const unicodeWithoutSpecialCharacterRegex = /^[a-zA-Z0-9]*$/g;

const integerStr = '-0123456789';
const positiveIntegerStr = '0123456789';
const decimalStr = '-.0123456789';
const decimalPositiveStr = '.0123456789';

const checkValidType = (str: string, value: string) => {
  return value.split('').every((item) => str.split('').includes(item));
};
interface HgbAntdInputProps extends Omit<InputProps, 'type'> {
  initValue?: string;
  label?: string;
  name: string;
  onChangeValue?: (name: any, value: string) => void;
  type?:
  | 'F'
  | 'H'
  | 'K'
  | 'F2H'
  | 'email'
  | 'integer'
  | 'positiveInteger'
  | 'decimal'
  | 'positiveDecimal'
  | 'password'
  | 'text'
  | 'bankCode'
  | 'area'
  | 'areaH'
  | 'unicodeWithoutSpecicalCharacter'
  | 'hgbEmail';
  search?: boolean;
  minValue?: number;
  maxValue?: number;
  subLabel?: React.ReactNode;
  decimalLength?: number;
  info?: string;
  modalStatus?: string;
  rows?: number;
  addonAfter?: React.ReactNode;
  palcement?: TooltipPlacement;
  infoTooltip?: React.ReactNode | RenderFunction;
  forceMaxDecimal?: number;
  fix16?: boolean;
  isCursorPosition?: boolean;
  onUpdateValue?: (name: string, value?: unknown) => void;
}

export const HgbAntdInput = React.forwardRef<any, HgbAntdInputProps>(
  (
    {
      fix16,
      isCursorPosition,
      label,
      required,
      className,
      onChangeValue,
      onUpdateValue,
      onChange,
      maxLength,
      search,
      minValue,
      maxValue,
      decimalLength,
      subLabel,
      info,
      modalStatus,
      rows = 3,
      addonAfter,
      infoTooltip,
      palcement,
      initValue,
      forceMaxDecimal,
      hidden,
      ...props
    },
    ref,
  ) => {
    const {
      watch,
      formState: { errors },
      setValue,
    } = useFormContext();
    const refInput = React.useRef<InputRef>(null);
    const [cursorPosition, setCursorPosition] = React.useState(0);
    React.useEffect(() => {
      const forceChangeEventHandler = () => {
        if (initValue !== undefined && initValue !== null) {
          setTimeout(() => {
            setValue(props.name, initValue);
          }, 100);
        }
      };
      forceChangeEventHandler();
      window.addEventListener(
        'FORCE_UPDATE_INIT_VALUE',
        forceChangeEventHandler,
      );
      return () =>
        window.removeEventListener(
          'FORCE_UPDATE_INIT_VALUE',
          forceChangeEventHandler,
        );
    }, [initValue]);

    React.useEffect(() => {
      onUpdateValue?.(props.name, watch(props.name));
    }, [props.name, watch(props.name)]);

    const valueWatch = watch(props.name);
    const error = errors[props.name];
    const inputId = React.useId();

    const onlyChangeWithCondition = (e: any) => {
      let value = '';
      value =
        (e as ClipboardEvent).clipboardData?.getData('text') ??
        (e as React.ChangeEvent<HTMLInputElement>).target.value;

      if (fix16) {
        let [inte, dec] = value.split('.');

        if (dec !== undefined) {
          inte = inte + '.'
        }

        if (dec?.length > 5) {
          dec = dec.slice(0, 5);

        }
        value = `${inte}${dec ?? ''}`;
      }

      if (props.type === 'password' && !isHalfSize(value)) {
        return;
      }
      switch (props.type) {
        case 'F':
          if (!isFullSize(value)) {
            return;
          }
          break;
        case 'H':
          case 'areaH':
          if (!isHalfSize(value)) {
            return;
          }
          break;
        case 'email':
          if (!isHalfSize(value) || !value.match(emailRegex)) {
            return;
          }
          break;
        case 'hgbEmail':
          if (!isHalfSize(value) || !value.match(emailRegex)) {
            return;
          }
          break;
        case 'integer':
          if (!checkValidType(integerStr, value)) {
            return;
          }
          break;
        case 'positiveInteger':
          if (!checkValidType(positiveIntegerStr, value)) {
            return;
          }
          break;
        case 'decimal':
          if (!checkValidType(decimalStr, value)) {
            return;
          }
          break;
        case 'positiveDecimal':
          if (!checkValidType(decimalPositiveStr, value)) {
            return;
          }
          break;
        case 'bankCode':
          if (!checkValidType(decimalPositiveStr, value)) {
            return;
          }
          break;
        case 'unicodeWithoutSpecicalCharacter':
          if (!value.match(unicodeWithoutSpecialCharacterRegex)) {
            return;
          }
          break;

        default:
          break;
      }
      switch (props.type) {
        case 'integer':
        case 'positiveInteger':
        case 'decimal':
        case 'positiveDecimal': {
          const newValue = value.replace(/[\uff01-\uff5e]/g, (c) =>
            String.fromCharCode(c.charCodeAt(0) - 0xfee0),
          );
          if (
            (props.type === 'integer' && !newValue.match(integerRegex)) ||
            (props.type === 'decimal' && !newValue.match(decimalRegex)) ||
            (props.type === 'positiveInteger' &&
              !newValue.match(positiveIntegerRegex)) ||
            (props.type === 'positiveDecimal' &&
              !newValue.match(positiveDecimalRegex))
          ) {
            return;
          }
          value = newValue;
          break;
        }
        default:
          break;
      }
      if (maxLength && value.length > maxLength) {
        value = value.slice(0, maxLength);
        isCursorPosition && setCursorPosition(e.target.selectionStart);
      }

      // Xử lý không cho người dùng nhập quá nhiều số sau dấu phẩy lớn hơn số lượng quy định
      if (
        value !== undefined &&
        value !== null &&
        value !== '' &&
        forceMaxDecimal !== undefined
      ) {
        const integerStr = value.split('.')?.[0] || '';
        let decimalStr = value.split('.')?.[1] || '';
        if (decimalStr.length > forceMaxDecimal) {
          decimalStr = decimalStr.slice(0, forceMaxDecimal);
          value = `${integerStr}.${decimalStr}`;
        }
        if (forceMaxDecimal === 0) {
          value = integerStr;
        }
      }

      setValue(props.name, value);
      onChangeValue?.(props.name, value);
    };
    const inputType = () => {
      switch (props.type) {
        case 'password':
          return 'password';
        case 'area':
        case 'areaH':
          return 'area';
        default:
          return 'other';
      }
    };

    React.useEffect(() => {
      if (refInput.current && isCursorPosition)
        setTimeout(
          () =>
            refInput.current!.setSelectionRange(cursorPosition, cursorPosition),
          0,
        );
    }, [cursorPosition]);

    return (
      <div className={cn({ 'tw-hidden': hidden }, className)}>
        {label && (
          <div className="tw-mb-4 tw-flex tw-justify-between">
            <label htmlFor={inputId}>
              <span className="tw-mr-4 tw-text-paragraph tw-font-bold">
                {label}
              </span>
              {required && (
                <span className="tw-text-paragraph tw-font-bold tw-text-error-7">
                  *
                </span>
              )}
              {modalStatus !== 'VIEW' && !!info ? (
                <HgbInfoLink to={info} target="_blank" />
              ) : null}
              {infoTooltip && (
                <HgbInfoTooltip title={infoTooltip} placement={palcement} />
              )}
            </label>
            {subLabel && <span className="tw-ml-8">{subLabel}</span>}
          </div>
        )}
        {inputType() === 'password' && (
          <Input.Password
            id={inputId}
            ref={ref}
            size="large"
            {...props}
            value={props.value ?? valueWatch}
            status={error && 'error'}
            onChange={onlyChangeWithCondition}
            iconRender={(visible) =>
              visible ? (
                <EyeOutlined tabIndex={0} />
              ) : (
                <EyeInvisibleOutlined tabIndex={0} />
              )
            }
            className={cn({ 'tw-opacity-80': props.disabled })}
          />
        )}

        {inputType() === 'other' ? (
          <Input
            addonAfter={addonAfter}
            prefix={
              search ? (
                <SearchOutlined className="tw-text-h3 tw-text-neutral-5" />
              ) : undefined
            }
            id={inputId}
            ref={refInput || ref}
            size="large"
            {...props}
            value={props.value ?? valueWatch ?? ''}
            status={error && 'error'}
            onChange={onlyChangeWithCondition}
            onBlur={(e) => {
              if (props.type === 'positiveDecimal') {
                let value =
                  !isEmpty(e.target.value) && !isNaN(Number(e.target.value))
                    ? e.target.value
                    : '';
                if (!!decimalLength) {
                  value = toRoundInput(value, decimalLength ?? 0);
                  // value = isEmpty(value)
                  //   ? ''
                  //   : exactmath.round(Number(value), -(decimalLength ?? 0), {
                  //       returnString: true,
                  //       trim: false,
                  //     });
                }
                if (!isNaN(maxValue) && Number(value) > Number(maxValue)) {
                  value = (maxValue ?? '').toString();
                }
                if (!isNaN(minValue) && Number(value) < Number(minValue)) {
                  value = (minValue ?? '').toString();
                }
                setValue(props.name, value);
              }
            }}
            className={cn({
              'tw-opacity-80': props.disabled,
              'hover:tw-border-neutral-2 focus:tw-border-neutral-2 focus:tw-shadow-none':
                props.readOnly,
            })}
          />
        ) : null}

        {inputType() === 'area' ? (
          <Input.TextArea
            id={inputId}
            ref={ref}
            size="large"
            rows={rows}
            value={props.value ?? valueWatch}
            status={error && 'error'}
            onChange={onlyChangeWithCondition}
            className={cn('tw-overflow-auto', {
              'tw-opacity-80': props.disabled,
              'hover:tw-border-neutral-2 focus:tw-border-neutral-2 focus:tw-shadow-none':
                props.readOnly,
            })}
            disabled={props.disabled}
            readOnly={props.readOnly}
          />
        ) : null}
        {error && <HgbFieldError>{error.message?.toString()}</HgbFieldError>}
      </div>
    );
  },
);
