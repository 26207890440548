import { EMAIL2_REGEX, IP_REGEX, IPV4_REGEX } from '@constants/regex';

export const checkIpAddress = (ips: string) => {
  return ips
    .split(';')
    .filter((item) => item !== '')
    .every((ip) => IPV4_REGEX.test(ip.trim()));
};

export const checkLoginInfoEmail = (emails: string) => {
  return emails
    .split(';')
    .filter((item) => item !== '')
    .every((email) => EMAIL2_REGEX.test(email.trim()));
};
