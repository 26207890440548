import { HgbModal } from '@common/antd/HgbModal';
import { HgbButton } from '@common/atoms/HgbButton';
import { HgbAntdInput } from '@common/forms';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBooleanState } from '@hooks/useBooleanState';
import { AuthenTemplate } from '@layouts/templates';
import { debug } from '@utils/debug';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useContext } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import * as yup from 'yup';

export type Reasons = {
  label: string;
  value: string;
};

type Widthdrawal = {
  companyName: string;
  personInChargeName: string;
  reasons: Reasons[];
  otherReasons: string;
  requests: string;
};

export const D09: React.FC = () => {
  const {
    text: { D09, E0000, common },
    routs,
  } = useContext(LanguageContext)!;
  const cancelModalState = useBooleanState(false);
  const cancelConfirmState = useBooleanState(false);

  const optionCheckBox: Reasons[] = [
    { label: D09.reason1, value: '0' },
    { label: D09.reason2, value: '1' },
    { label: D09.reason3, value: '2' },
    { label: D09.reason4, value: '3' },
    { label: D09.reason5, value: '4' },
    { label: D09.reason6, value: '5' },
    { label: D09.reason7, value: '6' },
    { label: D09.reason8, value: '7' },
    { label: D09.reason9, value: '8' },
  ];

  const WIDTHDRAWAL_DEFAULT_VALUE: Widthdrawal = {
    companyName: '',
    personInChargeName: '',
    reasons: optionCheckBox,
    otherReasons: '',
    requests: '',
  };

  const schema = yup.object({
    companyName: yup.string().notRequired(),
    personInChargeName: yup.string().notRequired(),
    reasons: yup.array().notRequired(),
    otherReasons: yup.string().when({
      is: () => {
        return widthdrawalMethods
          .watch('reasons')
          .some((item) => item.value === '8');
      },
      then: (schema) => schema.required(E0000(D09.otherReason)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    requests: yup.string().notRequired(),
  });

  const widthdrawalMethods = useForm<Widthdrawal>({
    defaultValues: WIDTHDRAWAL_DEFAULT_VALUE,
    resolver: yupResolver(schema),
  });

  const onChangeCheckbox = (checkedValue: CheckboxValueType[]) => {
    const reasons = checkedValue.map((item) => ({
      ...optionCheckBox.find((o) => o.value === item)!,
      more: '',
    }));
    widthdrawalMethods.setValue('reasons', reasons);
  };

  const widthdrawalSubmitHandler: SubmitHandler<Widthdrawal> = (data) => {
    cancelModalState.turnOn();
  };
  const widthdrawalSubmitError: SubmitErrorHandler<Widthdrawal> = (error) => {
    debug.log('error', error);
  };
  return (
    <AuthenTemplate title={D09.title}>
      <p>{D09.subTitle()}</p>
      <FormProvider {...widthdrawalMethods}>
        <div className="tw-flex tw-flex-col tw-justify-center tw-gap-28">
          <HgbAntdInput
            {...widthdrawalMethods.register('companyName')}
            type={'K'}
            label={D09.companyName}
            placeholder={D09.companyName}
            readOnly
          ></HgbAntdInput>
          <HgbAntdInput
            {...widthdrawalMethods.register('personInChargeName')}
            type={'K'}
            label={D09.picName}
            placeholder={D09.picName}
            readOnly
          ></HgbAntdInput>
          <div className="tw-flex tw-flex-col tw-gap-16">
            <div className="tw-flex tw-flex-col">
              <label className="tw-text-paragraph tw-font-bold">
                {D09.reason()}{' '}
                {widthdrawalMethods.formState.errors.otherReasons?.message && (
                  <span className="tw-text-paragraph tw-font-bold tw-text-error-7">
                    *
                  </span>
                )}
              </label>
            </div>
            <Checkbox.Group
              onChange={onChangeCheckbox}
              className="tw-grid tw-grid-cols-2 tw-gap-8 pc:tw-grid-cols-3"
              value={widthdrawalMethods
                .watch('reasons')
                .map((item) => item.value)}
            >
              {optionCheckBox.map((item) => {
                return (
                  <Checkbox
                    value={item.value}
                    key={item.value}
                    className="tw-items-center tw-bg-secondary-1 tw-p-4"
                  >
                    {item.label}
                  </Checkbox>
                );
              })}
            </Checkbox.Group>
            <HgbAntdInput
              maxLength={200}
              label={''}
              type="area"
              {...widthdrawalMethods.register('otherReasons')}
              className="tw-mt-8"
            />
          </div>
          <HgbAntdInput
            {...widthdrawalMethods.register('requests')}
            maxLength={2000}
            type="area"
            label={D09.request}
          ></HgbAntdInput>
          <div className="tw-flex tw-items-center tw-justify-center">
            <HgbButton
              htmlType="submit"
              type="primary"
              onClick={widthdrawalMethods.handleSubmit(
                widthdrawalSubmitHandler,
                widthdrawalSubmitError,
              )}
              className="tw-w-full pc:tw-w-160"
            >
              {D09.button}
            </HgbButton>
          </div>
        </div>
      </FormProvider>
      <HgbModal
        open={cancelModalState.value}
        cancelText={common.button.close}
        okText={common.button.approve}
        hgbLogo
        title={''}
        onCancel={cancelModalState.turnOff}
        onOk={cancelConfirmState.turnOn}
      >
        {D09.contentConfirmModalMember('jiji')}
      </HgbModal>
      <HgbModal
        open={cancelConfirmState.value}
        cancelText={common.button.close}
        hgbLogo
        title={''}
        onCancel={cancelConfirmState.turnOff}
      >
        {D09.contentSuccessModal()}
      </HgbModal>
    </AuthenTemplate>
  );
};
