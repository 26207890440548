import { HgbRadioGroup } from '@common/antd/HgbRadioGroup';
import { HgbButton, HgbButton2 } from '@common/atoms/HgbButton';
import { HgbResponsive } from '@common/components';
import {
  HgbAntdInput,
  HgbSelect,
  parseToHgbSelectOptions,
} from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { routs_en } from '@constants/ROUT';
import {
  STATUS_CODE
} from '@constants/consts';
import { MONTH_OF_YEAR_OPTIONS } from '@constants/options';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddress } from '@hooks/useAddress';
import { useBooleanState } from '@hooks/useBooleanState';
import { TemporaryTemplate } from '@layouts/templates/TemporaryTemplate';
import { SpsvCredit } from '@pages/Management/Setting/D08/SpsvCredit';
import { codeMajors, codeMediums } from '@pages/Management/Setting/D08/data';
import {
  createMajorOptions,
  createMediumOptions,
} from '@utils/mapFixedDataD01';
import { toJPFormat } from '@utils/number';
import { Checkbox } from 'antd';
import { debounce } from 'lodash';
import { FC, MouseEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useHgbMutationPost,
  useHgbMutationPut,
} from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  A07CheckValidURLResponse,
  A07RequestType,
  A07_DEFAULT_VALUE,
  CheckDurationRequestType,
  PaymentTypeRequest,
  PaymentTypeResponse,
  PaymentTypeResponseA07,
  SendEmailBankComplimentaryRequest
} from 'services/types/A07';
import * as yup from 'yup';
import './A07.scss';
import { debug } from '@utils/debug';
import { A07Term } from './A07Term';

const A07: FC = () => {
  const {
    text: { A07, A08, common, E0000 },
  } = useContext(LanguageContext)!;

  const [step, setStep] = useState<number>(1);

  const sendEmailBankComplimentaryMutation = useHgbMutationPut<
    undefined,
    SendEmailBankComplimentaryRequest
  >(API_URLS.SEND_EMAIL_BANK_COMPLIMENTARY, {
    isAlert: false,
    onSuccess: () => {
      return navigate(routs_en['/temporarily-register/success'].link, {
        state: 'success',
      });
    },
  });

  const schema = yup.object({
    enterpriseName: yup.string().required(E0000(common.label.enterpriseName)),
    enterpriseNameKana: yup
      .string()
      .required(E0000(common.label.enterpriseNameKana)),
    representative: yup.string().required(E0000(common.label.representative)),
    representativeKana: yup
      .string()
      .required(E0000(common.label.representativeKana)),
    postalCode: yup
      .string()
      .required(E0000(common.label.postalCode))
      .length(7, A07.message.postalCodeFormat),
    phoneNumber: yup.string().required(E0000(common.label.phoneNumber)),
    address1: yup.string().required(E0000(common.label.fullAddress)),
    startMonthOfYear: yup
      .string()
      .required(E0000(common.label.startMonthOfYear)),
    paymentType: yup.string().when({
      is: () => registerForm.watch().subscriptionType !== 'COMPLIMENTARY',
      then: (schema) => schema.required(E0000(common.label.paymentMethod)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    hasAccount: yup.string().required(E0000(common.label.isCorporateAccount)),
    branchNumber: yup.string().when({
      is: () => registerForm.watch().hasAccount === '1',
      then: (schema) => {
        return schema
          .required(E0000(common.label.branchNumber))
          .length(3, A07.message.branchNumberFormat);
      },
    }),
    corporateAccountNumber: yup.string().when({
      is: () => registerForm.watch().hasAccount === '1',
      then: (schema) => {
        return schema
          .required(E0000(common.label.corporateAccountNumber))
          .length(7, A07.message.corporateAccountNumberFormat);
      },
    }),
    picName: yup.string().required(E0000(common.label.picName)),
    picNameKana: yup.string().required(E0000(common.label.picNameKana)),
    industryCodeMajorClassification: yup
      .string()
      .required(E0000(common.label.industryMajorCodeClassification)),
    industryCodeMediumClassification: yup
      .string()
      .required(E0000(common.label.industryMediumCodeClassification)),
  });

  const registerForm = useForm<A07RequestType>({
    defaultValues: A07_DEFAULT_VALUE,
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const { registerId } = useParams();
  const [insert, setInsert] = useState(false);
  const [addressFromPostalCode] = useAddress(registerForm.watch().postalCode);
  const codeMajorOptions = createMajorOptions(codeMajors);
  const [isEditedMajor, setIsEditedMajor] = useState(false);
  const codeMediumOptions = useMemo(() => {
    const rs = createMediumOptions(
      codeMediums,
      registerForm.watch().industryCodeMajorClassification,
    );
    if (isEditedMajor) {
      registerForm.setValue('industryCodeMediumClassification', rs?.[0]?.value);
    } else {
      registerForm.setValue(
        'industryCodeMediumClassification',
        registerForm.watch().industryCodeMediumClassification,
      );
    }
    return rs;
  }, [registerForm.watch().industryCodeMajorClassification, isEditedMajor]);

  useEffect(() => {
    if (registerForm.watch().hasAccount === '2') {
      registerForm.setValue('branchNumber', '');
      registerForm.setValue('corporateAccountNumber', '');
    }
  }, [registerForm.watch().hasAccount]);

  useEffect(() => {
    if (!insert) {
      registerForm.setValue('address1', addressFromPostalCode.arch.full);
      if (addressFromPostalCode.error) {
        registerForm.setError('postalCode', { message: A07.message.postalCodeFormat });
      } else {
        registerForm.clearErrors('postalCode');
        registerForm.clearErrors('address1');
      }
    }
  }, [addressFromPostalCode, insert]);

  const enterpriseRegisterMutation = useHgbMutationPost<
    undefined,
    A07RequestType
  >(API_URLS.ENTERPRISE_REGISTER_API_URL, {
    onSuccess: (data) => {
      if (data && data.statusCode === STATUS_CODE.registerSuccess) {
        checkDurationMutation.mutate({
          registerId: registerId ?? '',
        });
        setStep(4);
      }
    },
  });

  const enterpriseUpdateMutation = useHgbMutationPut<
    undefined,
    A07RequestType
  >(API_URLS.ENTERPRISE_REGISTER_API_URL, {
    onSuccess: (data) => {
      if (data?.statusCode === STATUS_CODE.updateSuccess) {
        setStep(4);
      }
    },
  });

  const checkDurationMutation = useHgbMutationPost<
    A07CheckValidURLResponse,
    CheckDurationRequestType
  >(API_URLS.CHECK_DURATION_API_URL, {
    isAlert: false,
  });

  const { data: paymentQuery } = useHgbQuery<
    PaymentTypeResponseA07,
    PaymentTypeRequest
  >(
    API_URLS.PAYMENT_TYPE_LISTBOX,
    {
      enterpriseId: registerForm.watch().id,
      registerId: registerId,
    },
    {
      queryKey: [API_URLS.PAYMENT_TYPE_LISTBOX, registerForm.watch().id],
    },
  );

  const paymentOption = useMemo(
    () => parseToHgbSelectOptions(paymentQuery?.result?.methodType),
    [paymentQuery?.result],
  );

  const { data: haveNoneQuery } = useHgbQuery<PaymentTypeResponse, undefined>(
    API_URLS.HAVE_NONE_LISTBOX,
    undefined,
    {
      queryKey: [API_URLS.HAVE_NONE_LISTBOX],
    },
  );

  const haveNoneOption = useMemo(
    () => parseToHgbSelectOptions(haveNoneQuery?.result),
    [haveNoneQuery?.result],
  );

  const title = useMemo(() => {
    switch (step) {
      case 2:
        return A07.L2Title;
      case 3:
        return A07.L3Title;
      case 4:
        return A08.mainTitle;
      default:
        return A07.L1Title;
    }
  }, [step]);

  const handleSubmitBankComplimentary = () => {
    sendEmailBankComplimentaryMutation.mutate({
      id: registerForm.watch().bankId,
      email: registerForm.watch().picEmail,
    });
  };

  const callEnterpriseInfo = () => {
    const registerId = location.pathname.split('/temporarily-register/')[1]
    if (!registerId) {
      return navigate(routs_en['/temporarily-register/fail'].link, {
        state: 'fail' + registerForm.watch().expirationPeriodHours,
      });
    }
    checkDurationMutation.mutateAsync({
      registerId
    }).then((data) => {
      if (data.statusCode !== STATUS_CODE.success || data.result?.isExpired) {
        return navigate(routs_en['/temporarily-register/fail'].link, {
          state: 'fail' + data.result?.expirationPeriodHours,
        });
      }
      registerForm.reset({
        ...registerForm.watch(),
        ...data.result,
      });
      if (!data.result?.enterpriseLandingPage) {
        setInsert(true);
      } else {
        setInsert(false);
        registerForm.reset({
          ...registerForm.watch(),
          ...data.result,
          ...data.result.enterpriseLandingPage,
        });
        registerForm.setValue('id', data.result.enterpriseLandingPage.id)
      }
    });
  }

  useEffect(() => {
    callEnterpriseInfo();
  }, [])

  const onSubmit = () => {
    if (registerForm.watch('id')) {
      enterpriseUpdateMutation.mutateAsync({ ...registerForm.watch(), privacyPolicyUrl: '' })
        .then((data) => {
          if (data.statusCode === STATUS_CODE.updateSuccess) {
            setStep(2);
            submitAlready.current = true;
            callEnterpriseInfo();
          }
        });
      return
    }
    const { id, ...rest } = { ...registerForm.watch(), privacyPolicyUrl: '' }
    return enterpriseRegisterMutation.mutateAsync(rest as any)
      .then((data) => {
        if (data.statusCode === STATUS_CODE.registerSuccess) {
          setStep(2);
          submitAlready.current = true;
          callEnterpriseInfo();
        }
      })
  }

  const onSubmitError = (error: any) => {
    debug.log("error", error);
  }

  const submitAlready = useRef(false)
  const submitRef = useRef<HTMLButtonElement>(null)
  const handleClickButton = useCallback(debounce((e: MouseEvent<HTMLButtonElement>) => {
    const target = e.target as any
    const to = Number(target.dataset.to);
    const type = target.dataset.type;
    if (type === 'submit' && !submitAlready.current) {
      submitRef.current?.click();
    } else {
      setStep(to);
    }
  }, 500), [])

  const spsvCreditState = useBooleanState(false);

  const onSpsvSuccess = useCallback(() => {
    navigate(routs_en['/temporarily-register/success'].link, {
      state: 'success',
    });
  }, [])

  return <>
    <SpsvCredit
      from='unauthen'
      modalState={spsvCreditState}
      data={{
        bankCode: registerForm.watch('bankCode'),
        email: registerForm.watch('picEmail'),
        enterpriseId: registerForm.watch('id')
      }}
      onSuccess={onSpsvSuccess}
    />
    <TemporaryTemplate title={title}>
      <FormProvider {...registerForm}>
        <form
          className="tw-flex tw-flex-col tw-gap-16 pc:tw-gap-24"
          onSubmit={registerForm.handleSubmit(onSubmit, onSubmitError)}
        >
          <button type='submit' className='tw-absolute tw-w-0 tw-h-0' ref={submitRef} />
          {step === 1 && (
            <>
              <HgbResponsive
                gap
                className="tw-grid tw-w-full tw-flex-auto tw-grid-cols-1 tw-items-start tw-self-start tw-rounded-16 tw-bg-white tw-p-16 pc:tw-grid-cols-2 pc:tw-p-24"
              >
                <div>
                  <h4 className="tw-font-bold">{A07.label.bankName}</h4>
                  <h4>{registerForm.watch().bankName}</h4>
                </div>
                <HgbAntdInput
                  {...registerForm.register('enterpriseName')}
                  maxLength={100}
                  type="K"
                  required
                  className="tw-col-start-1"
                  label={common.label.enterpriseName}
                  placeholder={common.placeholder.enterpriseName}
                />
                <HgbAntdInput
                  {...registerForm.register('enterpriseNameKana')}
                  maxLength={100}
                  type="K"
                  required
                  label={common.label.enterpriseNameKana}
                  placeholder={common.placeholder.enterpriseNameKana}
                />
                <HgbAntdInput
                  {...registerForm.register('representative')}
                  maxLength={50}
                  type="K"
                  required
                  className="tw-col-start-1"
                  label={common.label.representative}
                  placeholder={A07.placeholder.representative}
                />
                <HgbAntdInput
                  {...registerForm.register('representativeKana')}
                  maxLength={50}
                  type="K"
                  required
                  label={common.label.representativeKana}
                  placeholder={A07.placeholder.representativeKana}
                />
                <HgbAntdInput
                  {...registerForm.register('postalCode')}
                  maxLength={7}
                  type={'integer'}
                  required
                  label={common.label.postalCode}
                  placeholder={common.placeholder.postalCode}
                  onChangeValue={() => setInsert(false)}
                />
                <HgbAntdInput
                  {...registerForm.register('address1')}
                  maxLength={100}
                  type="K"
                  required
                  className="tw-col-start-1"
                  label={common.label.fullAddress}
                  placeholder={A07.placeholder.address1}
                />
                <HgbAntdInput
                  {...registerForm.register('address2')}
                  maxLength={100}
                  type="K"
                  // required
                  className="tw-col-start-1"
                  label={common.label.address2}
                  placeholder={A07.placeholder.address2}
                />
                <HgbAntdInput
                  {...registerForm.register('phoneNumber')}
                  maxLength={11}
                  type={'integer'}
                  required
                  className="tw-col-start-1"
                  label={common.label.phoneNumber}
                  placeholder={common.placeholder.phoneNumber}
                />
                <HgbSelect
                  {...registerForm.register('startMonthOfYear')}
                  options={MONTH_OF_YEAR_OPTIONS}
                  label={common.label.startMonthOfYear}
                  required
                  placeholder={A07.placeholder.startMonthOfYear}
                />

                <HgbSelect
                  label={common.label.industryMajorCodeClassification}
                  className="tw-self-start"
                  {...registerForm.register('industryCodeMajorClassification')}
                  placeholder={''}
                  options={codeMajorOptions}
                  onChangeValue={() => setIsEditedMajor(true)}
                  required
                />
                <HgbSelect
                  label={common.label.industryMediumCodeClassification}
                  {...registerForm.register('industryCodeMediumClassification')}
                  placeholder={''}
                  options={codeMediumOptions}
                  required
                />
                {registerForm.watch().subscriptionType !== 'COMPLIMENTARY' && (
                  <HgbSelect
                    {...registerForm.register('paymentType')}
                    options={paymentOption}
                    label={common.label.paymentMethod}
                    required={
                      registerForm.watch().subscriptionType !== 'COMPLIMENTARY'
                    }
                    placeholder={''}
                    autoInitValue={true}
                  />
                )}
                <HgbRadioGroup
                  {...registerForm.register('hasAccount')}
                  options={haveNoneOption}
                  label={common.label.isCorporateAccount}
                  required
                />
                {registerForm.watch().hasAccount === '1' && (
                  <HgbAntdInput
                    {...registerForm.register('branchNumber')}
                    maxLength={3}
                    type={'integer'}
                    required
                    label={common.label.branchNumber}
                  />
                )}
                {registerForm.watch().hasAccount === '1' && (
                  <HgbAntdInput
                    {...registerForm.register('corporateAccountNumber')}
                    maxLength={7}
                    type={'integer'}
                    required
                    label={common.label.corporateAccountNumber}
                  />
                )}
                <HgbAntdInput
                  {...registerForm.register('picName')}
                  maxLength={50}
                  type={'K'}
                  required
                  label={common.label.picName}
                />
                <HgbAntdInput
                  {...registerForm.register('picNameKana')}
                  maxLength={50}
                  type={'K'}
                  required
                  label={common.label.picNameKana}
                />
                <div>
                  <h4 className="tw-font-bold">{common.label.picEmail}</h4>
                  <h4>{registerForm.watch().picEmail}</h4>
                </div>
              </HgbResponsive>
              <div className="tw-shrink-0 tw-self-center">
                <HgbButton2
                  className="tw-flex-shrink-0"
                  type='filled'
                  htmlType='button'
                  data-type='submit'
                  data-to='2'
                  debounceTime={0}
                  onClick={handleClickButton}
                >
                  <>{common.button.next}</>
                </HgbButton2>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className="tw-flex tw-flex-col tw-gap-8">
                <A07Term bankId={ registerForm.watch().bankId } />
                <a href={registerForm.watch().privacyPolicyUrl} target="__blank">
                  <p className="tw-text-primary-8">{A07.label.policy}</p>
                </a>
                <Checkbox
                  value={registerForm.watch().agreement}
                  checked={registerForm.watch().agreement}
                  onChange={(e) => registerForm.setValue('agreement', e.target.checked)}
                >
                  <p>{A07.label.L2Agreement}</p>
                </Checkbox>
              </div>
              <div className="tw-flex tw-shrink-0 tw-gap-16 tw-self-center">
                <HgbButton2
                  className="tw-flex-shrink-0"
                  type='outline'
                  htmlType='button'
                  data-to='1'
                  onClick={handleClickButton}
                  debounceTime={0}
                >
                  <>{common.button.return}</>
                </HgbButton2>
                <HgbButton2
                  className="tw-flex-shrink-0"
                  htmlType="button"
                  type='filled'
                  data-to='3'
                  disabled={!registerForm.watch().agreement}
                  onClick={handleClickButton}
                  debounceTime={0}
                >
                  <>{common.button.next}</>
                </HgbButton2>
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <HgbResponsive
                gap
                className="a07_3 tw-grid tw-w-full tw-flex-auto tw-grid-cols-1 tw-items-start tw-self-start tw-rounded-16 tw-bg-white tw-p-16 pc:tw-grid-cols-2 pc:tw-p-24"
              >
                <div className="tw-col-start-1">
                  <h4 className="tw-font-bold">{A07.label.bankName}</h4>
                  <h4>{registerForm.watch().bankName}</h4>
                </div>
                <div className="tw-col-start-1">
                  <h4 className="tw-font-bold">
                    {common.label.enterpriseName}
                  </h4>
                  <h4>{registerForm.watch().enterpriseName}</h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">
                    {common.label.enterpriseNameKana}
                  </h4>
                  <h4>{registerForm.watch().enterpriseNameKana}</h4>
                </div>
                <div className="tw-col-start-1">
                  <h4 className="tw-font-bold">
                    {common.label.representative}
                  </h4>
                  <h4>{registerForm.watch().representative}</h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">
                    {common.label.representativeKana}
                  </h4>
                  <h4>{registerForm.watch().representativeKana}</h4>
                </div>
                <div className="tw-col-start-1">
                  <h4 className="tw-font-bold">{common.label.postalCode}</h4>
                  <h4>{registerForm.watch().postalCode}</h4>
                </div>
                <div className="tw-col-start-1">
                  <h4 className="tw-font-bold">{common.label.fullAddress}</h4>
                  <h4>{registerForm.watch().address1}</h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">{common.label.address2}</h4>
                  <h4>{registerForm.watch().address2}</h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">{common.label.phoneNumber}</h4>
                  <h4>{registerForm.watch().phoneNumber}</h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">
                    {common.label.startMonthOfYear}
                  </h4>
                  <h4>
                    {
                      MONTH_OF_YEAR_OPTIONS.find(
                        (s) => s.value === registerForm.watch().startMonthOfYear,
                      )?.label
                    }
                  </h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">
                    {common.label.industryMajorCodeClassification}
                  </h4>
                  <h4>
                    {
                      codeMajorOptions.find(
                        (s) =>
                          s.value ===
                          registerForm.watch().industryCodeMajorClassification,
                      )?.label
                    }
                  </h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">
                    {common.label.industryMediumCodeClassification}
                  </h4>
                  <h4>
                    {
                      codeMediumOptions.find(
                        (s) =>
                          s.value ===
                          registerForm.watch().industryCodeMediumClassification,
                      )?.label
                    }
                  </h4>
                </div>
                {registerForm.watch().subscriptionType !== 'COMPLIMENTARY' && (
                  <div>
                    <h4 className="tw-font-bold">
                      {common.label.paymentMethod}
                    </h4>
                    <h4>
                      {
                        paymentOption.find(
                          (s) => s.value === registerForm.watch().paymentType,
                        )?.label
                      }
                    </h4>
                  </div>
                )}
                <div>
                  <h4 className="tw-font-bold">
                    {common.label.isCorporateAccount}
                  </h4>
                  <h4>
                    {
                      haveNoneOption.find(
                        (s) => s.value === registerForm.watch().hasAccount,
                      )?.label
                    }
                  </h4>
                </div>

                {registerForm.watch().hasAccount === '1' && (
                  <div>
                    <h4 className="tw-font-bold">
                      {common.label.branchNumber}
                    </h4>
                    <h4>{registerForm.watch().branchNumber}</h4>
                  </div>
                )}
                {registerForm.watch().hasAccount === '1' && (
                  <div>
                    <h4 className="tw-font-bold">
                      {common.label.corporateAccountNumber}
                    </h4>
                    <h4>{registerForm.watch().corporateAccountNumber}</h4>
                  </div>
                )}
                <div>
                  <h4 className="tw-font-bold">{common.label.picName}</h4>
                  <h4>{registerForm.watch().picName}</h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">{common.label.picNameKana}</h4>
                  <h4>{registerForm.watch().picNameKana}</h4>
                </div>

                <div>
                  <h4 className="tw-font-bold">{common.label.picEmail}</h4>
                  <h4>{registerForm.watch().picEmail}</h4>
                </div>
                <Checkbox
                  className="tw-col-start-1"
                  value={registerForm.watch().agreement}
                  checked={registerForm.watch().agreement}
                >
                  <p>{A07.label.L3Agreement}</p>
                </Checkbox>
              </HgbResponsive>
              <div className="tw-flex tw-shrink-0 tw-gap-16 tw-self-center">
                <HgbButton2
                  className="tw-flex-shrink-0 !tw-bg-white"
                  htmlType="button"
                  type='outline'
                  data-to='2'
                  onClick={handleClickButton}
                  debounceTime={0}
                >
                  <>{common.button.return}</>
                </HgbButton2>
                <HgbButton2
                  className="tw-flex-shrink-0"
                  htmlType="button"
                  type='filled'
                  data-to='4'
                  disabled={!registerForm.watch().agreement || !registerForm.watch('id')}
                  onClick={handleClickButton}
                  debounceTime={0}
                >
                  <>{common.button.enterPayment}</>
                </HgbButton2>
              </div>
            </>
          )}
          {step === 4 && (
            <>
              <HgbResponsive
                gap
                className="tw-flex tw-w-360 tw-flex-auto tw-flex-col tw-items-center tw-gap-24 tw-self-center tw-rounded-16 tw-bg-white tw-p-16 pc:tw-w-440 pc:tw-p-24"
              >
                <h2 className="tw-text-[28px] tw-font-bold">{A08.title}</h2>
                <p className="tw-text-center">
                  {A08.subTitle(
                    toJPFormat(
                      paymentQuery?.result?.enterpriseMonthlyFree || 0,
                      0,
                      false,
                    ),
                    paymentQuery?.result?.enterpriseTrialMonth,
                    registerForm.watch().subscriptionType,
                  )}
                </p>
                {registerForm.watch().subscriptionType !== 'COMPLIMENTARY' && (
                  <div className="tw-flex tw-flex-col tw-gap-24 tw-rounded-16 tw-bg-[#FFF4C9] tw-p-[10px]">
                    {A08.content(
                      toJPFormat(
                        paymentQuery?.result?.enterpriseMonthlyFree || 0,
                        0,
                        false,
                      ),
                    )}
                  </div>
                )}
              </HgbResponsive>
              <div className="tw-flex tw-shrink-0 tw-gap-16 tw-self-center">
                <HgbButton
                  className="tw-flex-shrink-0 !tw-bg-white"
                  type="primary"
                  htmlType="button"
                  ghost
                  onClick={() => {
                    setStep(3);
                    checkDurationMutation.mutate({
                      registerId: registerId ?? '',
                    });
                  }}
                >
                  <>{common.button.return}</>
                </HgbButton>
                {registerForm.watch().subscriptionType !== 'COMPLIMENTARY' ? (
                  <HgbButton2
                    className="tw-flex-shrink-0"
                    htmlType="button"
                    type='filled'
                    onClick={spsvCreditState.turnOn}
                    debounceTime={0}
                  >
                    <>{common.button.enterPayment}</>
                  </HgbButton2>
                ) : (
                  <HgbButton2
                    className="tw-flex-shrink-0"
                    htmlType="button"
                    type='filled'
                    onClick={handleSubmitBankComplimentary}
                    debounceTime={0}
                  >
                    <>{common.button.passedPayment}</>
                  </HgbButton2>
                )}
              </div>
              {registerForm.watch().subscriptionType !== 'COMPLIMENTARY' && (
                <p className="tw-self-center pc:tw-mb-24">{A08.notice}</p>
              )}
            </>
          )}
        </form>
      </FormProvider>
    </TemporaryTemplate>
  </>
};

export default A07;
