import { HgbActionButton } from '@common/antd/HgbActionButton';
import { HgbResponsive } from '@common/components';
import { HgbCellTooltip } from '@common/contents';
import {
  HgbDatePicker,
  HgbSelect,
  parseToHgbSelectOptions,
} from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { PaginationProps, usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { cn } from '@utils/cn';
import { emptyToUndefined } from '@utils/text';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { isEmpty } from 'lodash';
import moment from 'moment';
import dayjs from 'dayjs';
import { useContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHgbQuery, useHgbQueryWithPaging } from 'services/common/query';
import {
  GetBankListBoxRequest,
  GetBankListBoxResponse,
  StatusListBox,
} from 'services/types/bank';
import {
  GetEnterpriseListBoxRequest,
  GetEnterpriseListBoxResponse,
} from 'services/types/enterprise';

import { WithKey } from 'types/common';
import { HgbButton } from '@common/atoms/HgbButton';
import { HgbSpin } from '@common/antd/HgbSpin';
import { HgbEmpty } from '@common/antd/HgbEmpty';

type PaymentInvoicesRequest = {
  bankId: string;
  enterpriseId: string;
  billingMonth: string;
  billingStatus: string;
} & Partial<PaginationProps>;

interface PaymentInvoicesResponse {
  paymentDetailId: number;
  paymentStatus: string;
  paymentMonth: string;
  invoiceId: number;
  billingMethod: string;
  receiptId: number;
  enterpriseCode: string;
  bankCode: string;
}

const PAYMENT_INVOICE_SEARCH_DEFAULT: PaymentInvoicesRequest = {
  bankId: '',
  enterpriseId: '',
  billingMonth: moment().format('YYYY/MM'),
  billingStatus: '',
};

const D10 = () => {
  const {
    text: { common, D10 },
    language,
  } = useContext(LanguageContext)!;

  const { user } = useContext(AuthContext)!;

  const searchMethods = useForm<PaymentInvoicesRequest>({
    defaultValues: PAYMENT_INVOICE_SEARCH_DEFAULT,
  });

  const [enableAutoSearch, setEnableAutoSearch] = useState(false);

  const { paginationProps, PaginationComponent } = usePagin();

  const supplierParams: PaymentInvoicesRequest = useMemo(() => {
    const rs: PaymentInvoicesRequest = {
      bankId: searchMethods.watch('bankId'),
      enterpriseId: searchMethods.watch('enterpriseId'),
      billingMonth: searchMethods.watch('billingMonth'),
      billingStatus: searchMethods.watch('billingStatus'),
      ...paginationProps,
    };
    return rs;
  }, [searchMethods.watch(), paginationProps]);

  const transactionHistoryQuery = useHgbQueryWithPaging<
    PaymentInvoicesResponse[],
    PaymentInvoicesRequest
  >(API_URLS.PAYMENT_DETAILS, supplierParams, {
    enabled: enableAutoSearch,
    queryKey: [
      API_URLS.PAYMENT_DETAILS,
      language,
      supplierParams,
      user.accountId,
    ],
  });

  const { data: bankLBQuery, isFetchedAfterMount: bankLBFetchedAfterMount } =
    useHgbQuery<GetBankListBoxResponse, GetBankListBoxRequest>(
      API_URLS.BANK_LIST_BOX_API_URL,
      undefined,
      {
        queryKey: [language, API_URLS.BANK_LIST_BOX_API_URL],
        enabled: user.isPresidingBank,
      },
    );

  const enterpriseLBQuery = useHgbQuery<
    GetEnterpriseListBoxResponse,
    GetEnterpriseListBoxRequest
  >(
    API_URLS.ENTERPRISE_LIST_BOX_API_URL,
    {
      bankId: emptyToUndefined(searchMethods?.watch('bankId')),
    },
    {
      queryKey: [
        API_URLS.ENTERPRISE_LIST_BOX_API_URL,
        searchMethods?.watch('bankId'),
        language,
      ],
      enabled:
        user.isManagementBank ||
        (user.isPresidingBank && searchMethods?.watch('bankId') !== ''),
    },
  );

  const paymentStatusQuery = useHgbQuery<
    StatusListBox[],
    GetBankListBoxRequest
  >(
    API_URLS.PAYMENT_STATUS,
    { isLoadAllOption: true },
    { queryKey: [API_URLS.PAYMENT_STATUS, language] },
  );

  const bankLBOptions = useMemo(
    () => parseToHgbSelectOptions(bankLBQuery?.result),
    [bankLBQuery?.result],
  );

  const enterpriseLBOptions = useMemo(
    () => parseToHgbSelectOptions(enterpriseLBQuery?.data?.result),
    [enterpriseLBQuery?.data?.result],
  );

  const paymentStatusLBOptions = useMemo(
    () => parseToHgbSelectOptions(paymentStatusQuery?.data?.result),
    [paymentStatusQuery?.data?.result],
  );

  const handleGetInvoicePDF = (
    bankCode: string,
    paymentMethod: string,
    enterpriseCode: string,
  ) => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/${API_URLS.PAYMENT_DETAILS_INVOICES}/${paymentMethod}-invoice-${bankCode}-${enterpriseCode}`,
      '_blank',
    );
  };

  const handleGetReceiptPDF = (
    bankCode: string,
    paymentMethod: string,
    enterpriseCode: string,
  ) => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/${API_URLS.PAYMENT_DETAILS_RECEIPT}/${paymentMethod}-receipt-${bankCode}-${enterpriseCode}`,
      '_blank',
    );
  };

  const errorMessage = useMemo(() => {
    if (user.isPresidingBank) {
      if (isEmpty(bankLBOptions) && bankLBFetchedAfterMount) {
        return common.message.partnerBanksEmpty;
      }
      if (searchMethods.watch('bankId') === '') {
        return common.message.choosePartnerBankAndCompany;
      }
      if (isEmpty(enterpriseLBOptions)) {
        return common.message.companyEmpty;
      }
      if (isEmpty(searchMethods.watch('enterpriseId'))) {
        return common.message.chooseCompany;
      }
    }

    if (user.isManagementBank) {
      if (isEmpty(enterpriseLBOptions)) {
        return common.message.companyEmpty;
      }
      if (isEmpty(searchMethods.watch('enterpriseId'))) {
        return common.message.chooseCompany;
      }
    }
    return undefined;
  }, [
    user,
    searchMethods.watch(),
    enterpriseLBOptions,
    bankLBOptions,
    bankLBFetchedAfterMount,
    language,
  ]);

  const columns: ColumnsType<PaymentInvoicesResponse> = [
    {
      title: D10.label.billingMonth,
      dataIndex: 'paymentMonth',
      align: 'left',
      width: 300,
    },
    {
      title: D10.label.status,
      dataIndex: 'paymentStatus',
      align: 'left',
      width: 300,
    },
    {
      title: D10.column.paymentMethods,
      dataIndex: 'billingMethod',
      align: 'left',
      width: 300,
      render(value) {
        return <HgbCellTooltip>{value}</HgbCellTooltip>;
      },
    },
    {
      title: D10.column.download,
      dataIndex: 'invoiceId',
      align: 'center',
      width: 300,
      render(value, record) {
        return (
          <div className="tw-flex tw-items-center tw-justify-center tw-gap-24">
            <HgbActionButton
              disabled={!record.invoiceId}
              onClick={() =>
                handleGetInvoicePDF(
                  record?.bankCode,
                  dayjs(record.paymentMonth).format('YYYY-MM'),
                  record.enterpriseCode,
                )
              }
            >
              {D10.column.invoice}
            </HgbActionButton>
            <HgbActionButton
              disabled={!record.receiptId}
              onClick={() =>
                handleGetReceiptPDF(
                  record?.bankCode,
                  dayjs(record.paymentMonth).format('YYYY-MM'),
                  record.enterpriseCode,
                )
              }
            >
              {D10.column.receipt}
            </HgbActionButton>
          </div>
        );
      },
    },
  ];

  const rows: WithKey<PaymentInvoicesResponse>[] = useMemo(() => {
    return (transactionHistoryQuery?.data?.result?.content ?? []).map(
      (item) => ({
        ...item,
        key: item.invoiceId,
      }),
    );
  }, [transactionHistoryQuery?.data?.result?.content]);

  return (
    <ManagementTemplate>
      <FormProvider {...searchMethods}>
        <DataTableTemplate
          title={D10.pageTitle}
          paginationComponents={
            <PaginationComponent
              current={transactionHistoryQuery.data?.result?.currentPage}
              total={transactionHistoryQuery.data?.result?.totalRecord}
              pageSize={transactionHistoryQuery.data?.result?.pageSize}
            />
          }
          inputsComponent={
            <HgbResponsive className="tw-flex tw-flex-col tw-gap-16">
              {!user?.isEnterpriseAdmin && (
                <div className="tw-flex tw-flex-wrap tw-gap-24">
                  {!user?.isManagementBank && (
                    <HgbSelect
                      {...searchMethods.register('bankId')}
                      options={bankLBOptions}
                      label={D10.label.partnerBank}
                      className="tw-w-220"
                      disabled={isEmpty(bankLBOptions)}
                      onChangeValue={() =>
                        searchMethods.setValue('enterpriseId', '')
                      }
                      showSearch
                    />
                  )}

                  <HgbSelect
                    {...searchMethods.register('enterpriseId')}
                    options={enterpriseLBOptions}
                    label={D10.label.company}
                    className="tw-w-220"
                    disabled={isEmpty(enterpriseLBOptions)}
                    showSearch
                  />
                </div>
              )}
              <div className="tw-flex tw-flex-wrap tw-items-end tw-gap-24">
                <div
                  className={cn('tw-w-220', {
                    'tw-w-fit': user?.isEnterpriseAdmin,
                  })}
                >
                  <HgbDatePicker
                    picker="month"
                    label={D10.label.billingMonth}
                    {...searchMethods.register('billingMonth')}
                    format="YYYY/MM"
                    className="tw-w-120 tw-cursor-pointer"
                    allowClear
                    disabled={
                      !searchMethods.getValues('enterpriseId') &&
                      !user?.isEnterpriseAdmin
                    }
                  />
                </div>
                <HgbSelect
                  {...searchMethods.register('billingStatus')}
                  options={paymentStatusLBOptions}
                  label={D10.label.status}
                  className="tw-w-220"
                  disabled={
                    !searchMethods.getValues('enterpriseId') &&
                    !user?.isEnterpriseAdmin
                  }
                  autoInitValue
                />
                <HgbButton
                  className="tw-w-120"
                  type="primary"
                  onClick={() => setEnableAutoSearch(true)}
                >
                  {D10.label.search}
                </HgbButton>
              </div>
            </HgbResponsive>
          }
        >
          {rows.length > 0 && !errorMessage ? (
            <Table
              columns={columns}
              dataSource={rows}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
          ) : null}

          {transactionHistoryQuery.isLoading && !errorMessage ? (
            <HgbSpin placement="middle" />
          ) : null}

          {rows.length === 0 &&
          (errorMessage || transactionHistoryQuery.isFetchedAfterMount) ? (
            <HgbEmpty
              description={errorMessage ?? common.message.noResult}
              className="tw-flex tw-h-full tw-items-center tw-justify-center"
            />
          ) : null}
        </DataTableTemplate>
      </FormProvider>
    </ManagementTemplate>
  );
};

export default D10;
