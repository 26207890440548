import { EMAIL_REGEX } from '@constants/regex';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Enterprise,
  ENTERPRISE_FORM_DEFAULT_VALUE
} from 'services/types/enterprise';
import * as yup from 'yup';

export const useEnterprise = (enterprisePayload: Enterprise) => {
  const {
    text: { D01, common, E0000 },
  } = useContext(LanguageContext)!;
  const { user } = useContext(AuthContext)!;

  const validateBillingDate: any = () => {
    const billingStartDefault = enterprisePayload.billingStartDateApproved
      ? enterprisePayload.billingStartDateApproved
      : localStorage.getItem('billingStartDefault');
    return (
      dayjs(billingStartDefault).isBefore(enterprisePayload.billingStartDate) ||
      dayjs(billingStartDefault).isSame(enterprisePayload.billingStartDate)
    );
  };

  const enterpriseSchema = yup.object({
    enterpriseName: yup.string().required(E0000(common.label.enterpriseName)),
    enterpriseNameKana: yup
      .string()
      .required(E0000(common.label.enterpriseNameKana)),
    representative: yup.string().required(E0000(common.label.representative)),
    representativeKana: yup
      .string()
      .required(E0000(common.label.representativeKana)),
    postalCode: yup
      .string()
      .required(E0000(common.label.postalCode))
      .length(7, D01.message.postalCodeFormat),
    address1: yup.string().required(E0000(common.label.fullAddress)),
    // address2: yup.string().required(message.address2),
    startMonthOfYear: yup
      .string()
      .required(E0000(common.label.startMonthOfYear)),
    billingStartDate: yup
      .string()
      .required(E0000(common.label.billingDate))
      .when({
        is: () => user.subscriptionType !== 'COMPLIMENTARY',
        then: (schema) =>
          schema.test(
            D01.message.billingStartDateFormat,
            D01.message.billingStartDateFormat,
            () => validateBillingDate(),
          ),
        otherwise(schema) {
          return schema.notRequired();
        },
      }),

    availableScope3CategoryArray: yup.array().when({
      is: () => enterprisePayload.groupStatus === 'ACTIVE',
      then: (schema) =>
        schema
          .min(1, E0000(D01.label.scope3DirectInput))
          .required(E0000(D01.label.scope3DirectInput))
          .typeError(E0000(D01.label.scope3DirectInput)),
    }),
    paymentType: yup.string().required(E0000(common.label.paymentMethod)),
    hasAccount: yup.string().required(E0000(common.label.isCorporateAccount)),
    branchNumber: yup.string().when({
      is: () => enterprisePayload.hasAccount === '1',
      then: (schema) => {
        return schema
          .required(E0000(common.label.branchNumber))
          .length(3, D01.message.branchNumberFormat);
      },
    }),
    corporateAccountNumber: yup.string().when({
      is: () => enterprisePayload.hasAccount === '1',
      then: (schema) => {
        return schema
          .required(E0000(common.label.corporateAccountNumber))
          .length(7, D01.message.corporateAccountNumberFormat);
      },
    }),
    picName: yup.string().when({
      is: () => enterprisePayload.id?.toString() === '',
      then: (schema) => {
        return schema.required(E0000(common.label.picName));
      },
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),

    picNameKana: yup.string().when({
      is: () => enterprisePayload.id?.toString() === '',
      then: (schema) => {
        return schema.required(E0000(common.label.picNameKana));
      },
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    picEmail: yup
      .string()
      .required(E0000(common.label.picEmail))
      .matches(EMAIL_REGEX, common.message.emailFormat),
    isInvested: yup.string().required(E0000(D01.label.isInvested)),
    industryCodeMajorClassification: yup
      .string()
      .required(E0000(common.label.industryMajorCodeClassification)),
    industryCodeMediumClassification: yup
      .string()
      .required(E0000(common.label.industryMediumCodeClassification)),
  });
  const updateMethods = useForm<Enterprise>({
    resolver: yupResolver(enterpriseSchema),
    values: enterprisePayload,
  });

  return {
    enterpriseSchema,
    updateMethods,
  };
};
