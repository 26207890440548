import { PortalContext } from '@contexts/PortalContext';
import { hgbAxios } from '@utils/axios';
import { LocalDataClass } from 'data-class/LocalDataClass';
import { useContext, useEffect, useState } from 'react';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import { GetResponse, GetResponseWithPaging } from 'services/types/common';
import { objectToQueryString } from 'utils/objectToQueryString';
import { getMessageType } from './mutation';
import { result } from 'lodash';

export const FORCE_REFRESH_EVENT_NAME = 'FORCE_REFRESH';

export const useHgbQueryWithPaging = <TData, TVariables>(
  url: string,
  params?: TVariables,
  options?: UseQueryOptions<
    GetResponseWithPaging<TData>,
    unknown,
    GetResponseWithPaging<TData>,
    QueryKey
  >,
) => {
  const { addMessage } = useContext(PortalContext)!;
  const [refetchCount, setRefetchCount] = useState(0);

  const query = useQuery({
    queryKey: [url, JSON.stringify(options?.queryKey), refetchCount],
    refetchOnWindowFocus: false,
    ...options,
    queryFn: async () => {
      const language = LocalDataClass.language;
      const q = objectToQueryString(params);
      const queryString = `${url}?lang=${language}&${q}`;
      try {
        const rs = (await hgbAxios().get(queryString))?.data;

        return rs;
      } catch (error: any) {
        if (error?.code === 'ERR_NETWORK') {
          addMessage('error', 'Network error');
          return;
        }
        return undefined;
      }
    },
  });

  const refetch = () => {
    setRefetchCount((prev) => prev + 1);
  };

  useEffect(() => {
    window.addEventListener(FORCE_REFRESH_EVENT_NAME, refetch);
    return () => window.removeEventListener(FORCE_REFRESH_EVENT_NAME, refetch);
  }, []);
  return query;
};

export const useHgbQuery = <TData = any, TVariables = any>(
  url: string,
  params?: TVariables,
  options?: UseQueryOptions<
    GetResponse<TData>,
    unknown,
    GetResponse<TData>,
    QueryKey
  > & {
    type?: 'download' | 'upload' | 'blob' | undefined;
    isAlert?: boolean;
  },
) => {
  const { addMessage } = useContext(PortalContext)!;
  const [refetchCount, setRefetchCount] = useState(0);
  const query = useQuery({
    queryKey: [url, JSON.stringify(options?.queryKey), refetchCount],
    refetchOnWindowFocus: false,
    ...options,
    queryFn: async () => {
      try {
        const language = LocalDataClass.language;
        const q = objectToQueryString(params);
        const queryString = `${url}?lang=${language}&${q}`;
        const rsStamp = (await hgbAxios(options?.type).get(queryString))?.data;
        const messageType = getMessageType(rsStamp?.statusCode ?? 0);
        options?.isAlert && addMessage(messageType, rsStamp.message ?? '');
        return rsStamp;
      } catch (error: any) {
        if (error?.code === 'ERR_NETWORK') {
          addMessage('error', 'Network error');
          return;
        }
        if (options?.isAlert) {
          addMessage('error', error?.response?.data?.message);
        }
        return error?.response?.data;
      }
    },
  });

  const refetch = () => {
    setRefetchCount((prev) => prev + 1);
  };

  useEffect(() => {
    window.addEventListener(FORCE_REFRESH_EVENT_NAME, refetch);
    return () => window.removeEventListener(FORCE_REFRESH_EVENT_NAME, refetch);
  }, []);
  return query;
};

type DownloadData<T = any> = {
  fileName: string;
  file: T;
  message?: string;
};
export const useHgbQueryDownload = <TData, TVariables>(
  url: string,
  params?: TVariables,
  options?: UseQueryOptions<
    DownloadData<TData>,
    unknown,
    DownloadData<TData>,
    QueryKey
  >,
) => {
  const { addMessage } = useContext(PortalContext)!;

  let queryFn = async () => {
    const language = LocalDataClass.language;
    const q = objectToQueryString(params);
    const queryString = `${url}?lang=${language}&${q}`;
    try {
      const rs = await hgbAxios('download').get(queryString);
      const jsonData: any = arrayBufferToJSON(rs.data);

      if (!jsonData.statusCode) {
        jsonData.fileName = (rs.headers?.['content-disposition'] ?? '')
          .toString()
          .split('; filename=')[1];
        jsonData.file = rs?.data;
      }
      return jsonData;
    } catch (error) {
      return {};
    }
  };

  const query = useQuery({
    queryKey: url,
    refetchOnWindowFocus: false,
    ...options,
    queryFn,
  });

  const fetchCustom = () => {
    return new Promise((resolve, reject) => {
      query.refetch().then((rs: any) => {
        if (rs?.data?.statusCode) {
          reject(rs.data);
          return;
        }
        resolve(rs.data);
      });
    });
  };

  return { ...query, fetchCustom };
};

const arrayBufferToJSON = (arrayBuffer: any) => {
  try {
    const uint8Array = new Uint8Array(arrayBuffer);
    const decoder = new TextDecoder('utf-8');
    const jsonString = decoder.decode(uint8Array);
    const jsonParse = JSON.parse(jsonString);
    return {
      message: (jsonParse.message ?? '') as string,
      statusCode: (jsonParse.statusCode ?? 0) as number,
    };
  } catch (error) {
    return {
      message: '',
      statusCode: 0,
    };
  }
};
