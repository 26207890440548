export const PASSWORD_REGEX = new RegExp(
  '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*\\W).+$',
);
export const EMAIL_REGEX = new RegExp(
  '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
);
const octet = '(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])';
const sextet = '[0-9a-fA-F]{1,4}';
const ipv4 = `(${octet}\\.){3}${octet}`;
export const IP_REGEX = new RegExp(
  `
  ^(
    (${sextet}:){7}${sextet}|
    (${sextet}:){1,7}:|
    (${sextet}:){1,6}:${sextet}|
    (${sextet}:){1,5}(:${sextet}){1,2}|
    (${sextet}:){1,4}(:${sextet}){1,3}|
    (${sextet}:){1,3}(:${sextet}){1,4}|
    (${sextet}:){1,2}(:${sextet}){1,5}|
    ${sextet}:((:${sextet}){1,6})|
    :(((:${sextet}){1,7})|:)|
    fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|
    ::(ffff(:0{1,4}){0,1}:){0,1}${ipv4}|
    (${sextet}:){1,4}:${ipv4}|
    ${ipv4}
  )$
  `.replace(/\s+/g, ''),
);
export const IPV4_REGEX = new RegExp('^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$');
export const DATE_REGEX = new RegExp('^([1-9]\\d{3})\\/(0[1-9]|1[0-2])$');
export const SPLIT_FORMULA_FIELDS_REGEX = new RegExp(
  '[\\s()\\-+*\\/^－.]',
  'g',
);
export const FULL_SIZE_REGEX = new RegExp('[\\uff01-\\uff5e]', 'g');
export const DOUBLE_BYTES_SPACE_REGEX = new RegExp('\u3000', 'g');
export const PLUS_MINUS_SIGN_REGEX = new RegExp('[\\+\\-]', '');
export const ONE_OR_MORE_NONE_ALPHA_NUMERIC_REGEX = new RegExp(
  '[^a-zA-Z0-9\\-]+',
  'g',
);
export const EMAIL2_REGEX = new RegExp(
  '^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$',
);
