
import { HgbSelectOption, parseToHgbSelectOptions } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { debug } from '@utils/debug';
import { fixNumber } from '@utils/number';
import { convertObjectToTree, getParentKey } from '@utils/object.utils';
import { joinSafe } from '@utils/text';
import { LocalDataClass } from 'data-class/LocalDataClass';
import exactmath from 'exact-math';
import { isEmpty } from 'lodash';
import { useContext, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useHgbQuery } from 'services/common/query';
import {
  BaseOrganizationListBoxRequest,
  BaseOrganizationListBoxResponse,
} from 'services/types/base';
import {
  GetCategoryListBoxRequest,
  GetCategoryListBoxResponse,
} from 'services/types/category';
import {
  GetScopeListBoxRequest,
  GetScopeListBoxResponse,
} from 'services/types/scope';
import {
  GetFiscalYearMonthListBoxRequest,
  GetFiscalYearMonthListBoxResponse,
  GetLastTenYearListBoxRequest,
  GetLastTenYearListBoxResponse,
} from 'services/types/year';
import { MANUAL_NAMEDDESTS } from '../Support';
import {
  Emission,
  FlowInputDetail,
  InputInfo,
  OffsetFields,
  SelectionInfo,
  Step1Fields,
  Step2Fields,
} from './type';

export const unitByRange = (intensity?: InputInfo) => {
  if (!intensity) return '';
  if (!isEmpty(intensity.constraints)) {
    return `${intensity.constraints.MIN_VALUE} - ${intensity.constraints.MAX_VALUE}`.replaceAll(
      'null',
      '',
    );
  }
  return '';
};

export const offsetAmountUnitOptions = [
  { label: 'tCO2', value: 'tCO2' },
  { label: 'kgCO2', value: 'kgCO2' },
];

export const getResultFromFormula = (
  _values: Record<string, any>,
  formula?: null | string,
) => {
  const ExactMath = exactmath;
  ExactMath.Mul = (...args: any) => {
    return exactmath.mul(...args, { returnString: true })
  }
  ExactMath.Div = (...args: any) => {
    return exactmath.div(...args, { returnString: true })
  }
  ExactMath.Add = (...args: any) => {
    return exactmath.add(...args, { returnString: true })
  }
  ExactMath.Sub = (...args: any) => {
    return exactmath.sub(...args, { returnString: true })
  }
  ExactMath.Pow = (...args: any) => {
    return exactmath.pow(...args, { returnString: true })
  }

  ExactMath.pow = Math.pow;
  ExactMath.log = Math.log;
  ExactMath.E = Math.E;
  let variables = getParamsFromFormula(formula || '')
    .filter(item => item !== 'consumptionOfFuel');

  formula = formula?.replaceAll('Math.', 'ExactMath.')
    .replaceAll(' ', '')
    .replaceAll(',,', ',')
  formula = formula?.replaceAll('ExactMath.mul', 'ExactMath.Mul')
    .replaceAll('ExactMath.div', 'ExactMath.Div')
    .replaceAll('ExactMath.add', 'ExactMath.Add')
    .replaceAll('ExactMath.sub', 'ExactMath.Sub')
    .replaceAll('ExactMath.pow', 'ExactMath.Pow')
    .replaceAll('ln(', 'ExactMath.log(')

  try {
    let result: any = '';
    eval(
      `   
        let {${variables.join(',')} } = _values;
        variables.forEach(item=>{
          formula = formula.replace(item,"'" + _values[item] +"'");
          })
        let consumptionOfFuel = window.consumptionOfFuel
        eval('result='+ formula);
      `,
    );
    if (
      (result || '').toString() === 'NaN' ||
      (result || '').toString() === 'Infinity'
    ) {
      return ''
    }
    let [inte, dec] = result.split('.');
    let decNumber = 0;
    if(dec !== undefined) {
      decNumber = 16 - inte?.length - 1
    }
    result = fixNumber(result.toString(), decNumber);
    debug.log("Result:", result)
    return result;
  } catch (error) {
    debug.log("error", error);
    return undefined;
  }
};

export const getParamsFromFormula = (formula: string) => {
  let arr: string[] = [];
  const charactersToReplace = ['*', '/', '+', '-', '(', ')'];
  charactersToReplace.forEach((char) => {
    formula = `${formula.replaceAll(char, ',')}`;
  });

  arr = formula
    .split(',')
    .map((e) => e.trim())
    .filter(
      (value) =>
        Number(value).toString() === 'NaN' &&
        !value?.includes('Math.') && value !== 'ln' &&
        (value || '').trim() !== '',
    );
  return Array.from(new Set(arr));
};

export const STEP1_DEFAULT_VALUE: Step1Fields = {
  targetYearMonth: '',
  targetFiscalYear: '',
  baseId: '',
  scopeCode: '',
  categoryCode: '',
  greenHouseCode: '',
};

export const STEP2_DEFAULT_VALUE: Step2Fields = {
  emissionUnit: '',
  calculationTargetCode: '',
  calculationMethodCode: '',
  scope2MethodSelected: '',
  fuelCode: '',
  activityVolume: undefined,
  activityVolumeUnit: '',
  emissionIntensity: undefined,
  emissionIntensityUnit: '',
  emissionVolume: '',
  emissionVolumeUnit: '',
  modelCode: '',
  refrigerantNo: '',
  fillingVolume: undefined,
  fillingVolumeUnit: '',
  recoveryVolume: undefined,
  recoveryVolumeUnit: '',
  useEmissionCoefficient: undefined,
  useEmissionCoefficientUnit: '',
  gwp: undefined,
  gwpUnit: '',
  activityName: '',
  supplyMenuCode: '',
  retailElectricCalculationMethodCode: '',
  operatorGraspRate: undefined,
  operatorGraspRateUnit: '',
  heatUsageCode: '',
  departmentCode: '',
  rawActivityVolume: undefined,
  rawEmissionIntensity: undefined,
  rawTransportActivityVolume: undefined,
  rawTransportEmissionIntensity: undefined,
  capitalWasteActivityVolume: undefined,
  capitalWasteEmissionIntensity: undefined,
  energyTypeCode: '',
  electricCompanyNo: '',
  transportTypeCode: '',
  transportDistance: undefined,
  transportDistanceUnit: '',
  transportCargoWeight: undefined,
  transportCargoWeightUnit: '',
  transportMaximumCargoWeight: undefined,
  transportMaximumCargoWeightUnit: '',
  loadPercentageCode: '',
  transportLoadRate: undefined,
  transportLoadRateUnit: '',
  consumptionTransportTypeCode: '',
  consumption: undefined,
  consumptionUnit: '',
  radioButton1: '',
  radioButton2: '',

  numberOfPeople: undefined,
  numberOfPeopleUnit: '',
  numberOfNight: undefined,
  numberOfNightUnit: '',
  numberOfDay: undefined,
  numberOfDayUnit: '',
  businessTravelTypeCode: '',

  transportProduct: '',
  employeeNumberBasedTypeCode: '',
  nightStayTypeCode: '',

  fuelEconomyBasic: undefined,
  fuelEconomyBasicUnit: '',

  officeCityClassificationCode: '',

  buildingUseCode: '',
  distribution: undefined,
  distributionUnit: '',

  expectedLifetimeUsagePer: undefined,
  expectedLifetimeUsagePerUnit: '',
  saleCount: undefined,
  saleCountUnit: '',
  perLessonUsage: undefined,
  perLessonUsageUnit: '',
  capitalGoodsTypeCode: '',
  emissionLifetimeUse: undefined,
  greenHouseGasCode: '',
  businessDaysYear: undefined,
  expectedLifetimeUsage: undefined,
  expectedLifetimeUsagePercentage: undefined,
  unclearActivityVolume1: undefined,
  unclearActivityVolume2: undefined,
  unclearActivityVolume3: undefined,
  unclearActivityVolume4: undefined,
  unclearActivityVolume5: undefined,
  unclearActivityVolume6: undefined,
  unclearActivityVolume7: undefined,
  unclearActivityVolume8: undefined,
  unclearActivityVolume9: undefined,
  unclearActivityVolume10: undefined,
  unclearActivityVolume11: undefined,
  unclearActivityVolume12: undefined,
  unclearEmissionIntensity1: undefined,
  unclearEmissionIntensity2: undefined,
  unclearEmissionIntensity3: undefined,
  unclearEmissionIntensity4: undefined,
  unclearEmissionIntensity5: undefined,
  unclearEmissionIntensity6: undefined,
  manualIdeaInput: undefined,
  emissionIntensityLocation: undefined,
  emissionVolumeLocation: '',
  emissionIntensityLocationUnit: '',
  emissionVolumeLocationUnit: '',
  fuelEfficiencyCategory: undefined,
  companyGasSelected: undefined,
};

export const OFFSET_DEFAULT: OffsetFields = {
  offsetAmount: undefined,
  offsetAmountUnit: '',
  offsetCertificateDate: '',
  offsetTarget: '',
  projectName: '',
  creditType: '',
};

export const useLastTenYearsListBox = (enterpriseId?: string) => {
  const { user } = useContext(AuthContext)!;
  const payload =
    user.isPresidingBank || user.isManagementBank ? enterpriseId : undefined;
  const lastTenYearsListBoxQuery = useHgbQuery<
    GetLastTenYearListBoxResponse,
    GetLastTenYearListBoxRequest
  >(
    API_URLS.LAST_TEN_FISCAL_YEAR,
    { enterpriseId: payload },
    {
      queryKey: [API_URLS.LAST_TEN_FISCAL_YEAR, enterpriseId],
    },
  );

  const options = useMemo(
    () =>
      parseToHgbSelectOptions(lastTenYearsListBoxQuery.data?.result).filter(
        (word) => Number(word.value) > 2018,
      ),
    [JSON.stringify(lastTenYearsListBoxQuery.data?.result)],
  );
  return { options };
};

export const useCategory = (scope: string, enterpriseId: number) => {
  const categoryQuery = useHgbQuery<
    GetCategoryListBoxResponse,
    GetCategoryListBoxRequest
  >(
    API_URLS.CATEGORY_LIST_BOX_API_URL,
    { scopeCode: '3', enterpriseId: enterpriseId },
    {
      enabled: scope === '3',
      queryKey: [API_URLS.CATEGORY_LIST_BOX_API_URL],
    },
  );
  const options = useMemo(
    () =>
      (categoryQuery.data?.result || []).map(
        ({ name: label, value, note }) => ({
          label: scope !== '3' ? '' : joinSafe([label, note], ' : '),
          value,
        }),
      ),
    [JSON.stringify(categoryQuery.data?.result), scope],
  );
  return { options };
};

export const useScope = () => {
  const {
    text: { common },
  } = useContext(LanguageContext)!;
  const scopeQuery = useHgbQuery<
    GetScopeListBoxResponse,
    GetScopeListBoxRequest
  >(API_URLS.SCOPE_LIST_BOX_API_URL);
  const options = useMemo(
    () => [
      ...(scopeQuery.data?.result || []).map((item) => ({
        label: joinSafe([item.name, item.note], ' : '),
        value: item.value,
      })),
      { label: common.label.offset, value: '4' },
    ],
    [JSON.stringify(scopeQuery.data?.result), common.label.offset],
  );
  return { options };
};

export const useFiscalYearMonth = (targetYear: string) => {
  const fiscalYearMonthQuery = useHgbQuery<
    GetFiscalYearMonthListBoxResponse,
    GetFiscalYearMonthListBoxRequest
  >(
    API_URLS.FISCAL_YEAR_MONTH,
    {
      targetYear,
      isLCIDBYear: false,
    },
    {
      enabled: !!targetYear,
      queryKey: [API_URLS.FISCAL_YEAR_MONTH, targetYear],
    },
  );

  const options = useMemo(() => {
    let years = fiscalYearMonthQuery.data?.result || [];
    return years.map((item) => ({ ...item, label: item.name }));
  }, [JSON.stringify(fiscalYearMonthQuery.data?.result)]);
  return { options };
};

export const useBase = (params?: boolean) => {
  const basesQuery = useHgbQuery<
    BaseOrganizationListBoxResponse,
    BaseOrganizationListBoxRequest
  >(
    API_URLS.ORGANIZATION_BASE,
    {
      isLoadInactive: params,
    },
    {
      queryKey: [API_URLS.ORGANIZATION_BASE],
    },
  );

  const options = useMemo(
    () =>
      convertObjectToTree(
        (basesQuery?.data?.result || []).filter(
          (item) => !getParentKey(item.hierarchicalPath ?? ''),
        ) as any,
        (basesQuery?.data?.result || []) as any,
      ),
    [JSON.stringify(basesQuery?.data?.result)],
  );

  return { options };
};

export const uniqPropertyArray = <T>(arr: T[], key: keyof T): T[] => {
  const uniqueItems: Record<string, T> = {};

  arr.forEach((item) => {
    uniqueItems[item[key] as string] = item; // Lưu phần tử cuối cùng cho mỗi key
  });
  // Trả về mảng các giá trị mới lấy phần tử cuối cùng trùng nhau
  return Object.values(uniqueItems);
};

export const isSearchSelect = (
  fieldTarget: string,
  scopeCode: string,
  categoryCode: string,
) => {
  const searchList = [
    'fuelCode',
    'electricCompanyNo',
    'officeCityClassificationCode',
    'greenHouseGasCode',
    'calculationMethodCode',
    'capitalGoodsTypeCode',
    'departmentCode',
    'heatUsageCode',
    'businessTravelTypeCode',
    'departmentCode',
    'heatUsageCode',
    'certificateSelected',
    'greenHouseGasOptionSelected',
  ];
  if (scopeCode === '1' || scopeCode === '2') {
    return searchList.includes(fieldTarget);
  } else {
    if (fieldTarget === 'Type') return categoryCode === '01';
    return searchList.includes(fieldTarget);
  }
};

export const createSelectioInfoOptions = (
  options: { value: string; name: string }[] = [],
  fieldTarget: string,
): HgbSelectOption[] => {
  let result = options.map(({ name, value }) => ({ value, label: name }));
  if (fieldTarget === 'supplyMenuCode') {
    result = result.sort((p1, p2) =>
      p1.value > p2.value ? 1 : p1.value < p2.value ? -1 : 0,
    );
  }
  return result;
};

export const getInputType = (targetField: string) => {
  return ['numberOfPeople', 'numberOfDay', 'numberOfNight'].includes(
    targetField,
  )
    ? 'positiveInteger'
    : 'positiveDecimal';
};

export const getInfoLink = (fieldTarget: string) => {
  return fieldTarget === 'scope2MethodSelected'
    ? '/support/manual/' + MANUAL_NAMEDDESTS.about_market_location_base
    : '';
};

export const preventInfinity = (value?: string | number) => {
  const valueStr = value?.toString();
  if (valueStr === 'Infinity' || valueStr === 'NaN') return '';
  return valueStr || '';
};

export const preventNull = (value?: string) => {
  return (value || '').replaceAll('null', '');
};

export const getIntersectionFieldTarget = (
  name: string,
  additionalSelectionInfos: SelectionInfo[],
) => {
  const { length } = additionalSelectionInfos;
  if (length === 0) return name;
  if (additionalSelectionInfos.some((item) => item.fieldTarget === name)) {
    return additionalSelectionInfos.at(-1)!.fieldTarget;
  }
  return name;
};

export const preValidateInputInfoCondition = (
  name: keyof Step2Fields,
  flowInputDetails: FlowInputDetail[],
) => {
  const checkers: InputInfo[] = [];
  flowInputDetails
    .map((flow) => flow.inputGroup)
    .forEach((input) => {
      input?.forEach((rs) => {
        if (rs.fieldTarget === name) {
          checkers.push(rs);
        }
      });
    });
  return checkers.length > 0;
};

export const preValidateIntensityCondition = (
  name: keyof Step2Fields,
  flowInputDetails: FlowInputDetail[],
) => {
  const checkers: InputInfo[] = [];
  flowInputDetails
    .map((flow) => flow.intensity)
    .filter((s) => s)
    .forEach((intensity) => {
      if (intensity.disabled === false && intensity.fieldTarget === name) {
        checkers.push(intensity);
      }
    });
  return checkers.length > 0;
};

export const unitFlowInputDetails = (flowInputDetails: FlowInputDetail[]) => {
  const extractedUnits: Record<string, string> = {};

  flowInputDetails.forEach((group) => {
    if (group.inputGroup) {
      group.inputGroup.forEach((input) => {
        const key = `${input.fieldTarget}Unit`;
        extractedUnits[key] = input.unit;
      });
    }
    if (group.intensity) {
      const key = `${group.intensity.fieldTarget}Unit`;
      extractedUnits[key] = group.intensity.unit;
    }
  });

  return extractedUnits;
};

export const createEmissionApiLink = (
  greenHouseGas: string,
  scopeCode: string,
  categoryCode: string,
  id?: number,
) => {
  if (greenHouseGas && greenHouseGas !== 'CO2') {
    return `${API_URLS.EMISSION_INPUT_GREEN_HOUSE_GAS}`;
  } else {
    if (scopeCode === '4') return API_URLS.OFFSET_API_URL;

    const scopePart = `/scope-${scopeCode}`;
    let categoryPart = '';
    if (categoryCode && categoryCode !== '00')
      categoryPart = `/category-${Number(categoryCode)}${id ? '' : '/save'}`;

    return `/api/v1/emission-input${scopePart}${categoryPart}`;
  }
};

export const disableStep2Submit = (
  formula = '',
  emission: Emission,
  checkFlowFinished: boolean,
) => {
  let disabled = false;
  const { scopeCode } = emission;

  if (scopeCode === '4') {
    const { offsetAmount, offsetCertificateDate } = emission;
    if (isEmpty(offsetAmount?.toString()) || isEmpty(offsetCertificateDate)) {
      disabled = true;
    }
    return disabled;
  }

  if (!checkFlowFinished) return true;

  const keyFormula = getParamsFromFormula(formula).filter(item => item !== 'consumptionOfFuel');

  keyFormula.forEach((name) => {
    if (
      emission[name] === undefined ||
      emission[name] === '' ||
      emission[name] === null
    ) {
      disabled = true;
    }
  });

  if (
    scopeCode === '3' &&
    emission?.greenHouseCode === 'CO2' &&
    (emission.activityName || '') === ''
  ) {
    disabled = true;
  }

  return disabled;
};

export const createFlowApi = (
  scope: string,
  category: string,
  params?: Record<string, any>,
) => {
  if (params?.greenHouseCode && params?.greenHouseCode !== 'CO2') {
    return `${API_URLS.EMISSION_INPUT_GREEN_HOUSE_GAS_FLOW}?lang=${LocalDataClass.language}`;
  }
  return `api/v1/emission-input${scope}/flow${category}?lang=${LocalDataClass.language}`;
};

export const isFullData = (
  selectionInfos: SelectionInfo[],
  params: Record<string, any>,
) => {
  return selectionInfos.every(
    (selection) => (params[selection.fieldTarget] || '') !== '',
  );
};

export const shouldResetNextSelections = (
  currentNextSelections: SelectionInfo[],
  fieldTarget: string,
  nextStepSelectionInfos: SelectionInfo[],
) => {
  // Nếu có nextStepSelectionInfos thì cho phép nối thêm và reset
  if (nextStepSelectionInfos.length > 0) return true;

  // Nếu không có thì check lại fieldTarget vừa chọn có nằm trong đống currentNextSelections hay không, nếu có thì không cho phép reset
  if (
    currentNextSelections.length > 1 &&
    currentNextSelections.map((item) => item.fieldTarget).includes(fieldTarget)
  ) {
    return false;
  }

  // Nếu không nằm trong 2 trường hợp trên thì cho phép reset
  return true;
};

export const getSelectionsTarget = (
  name: string,
  nextSelectionsList: string[][],
  newSelectionInfos: SelectionInfo[],
) => {
  let targetName = name;
  let selectionsTarget: string[] = [];
  nextSelectionsList.forEach((nextSelections) => {
    if (
      nextSelections.includes(name) &&
      nextSelections.every((selection) =>
        newSelectionInfos.find((item) => item.fieldTarget === selection),
      )
    ) {
      selectionsTarget = nextSelections;
    }
  });
  if (selectionsTarget.length) {
    targetName = selectionsTarget.at(-1)!;
  }
  return {
    targetName,
    nameList: selectionsTarget,
  };
};

export const updateSelectionInfos = (
  name: string,
  value: string,
  currentSelectionInfos: SelectionInfo[],
  step2Methods: UseFormReturn<Step2Fields, any, undefined>,
): SelectionInfo[] => {
  const index = currentSelectionInfos.findIndex(
    (selection) => selection.fieldTarget === name,
  );

  if (
    [
      'supplyCompanyNo',
      'electricCompanyNo',
      'companyGasSelected',
      'loadPercentageCode',
    ].includes(name)
  ) {
    return currentSelectionInfos.slice(0, index + 2);
  }

  if (
    name === 'radioButton2' &&
    step2Methods.getValues('calculationMethodCode') === 'M00018'
  ) {
    return currentSelectionInfos.slice(0, index + 2);
  }

  if (
    name === 'calculationMethodCode' &&
    ['M00018', 'ST034', 'ST054', 'ST055', 'ST035', 'M00036', 'M00035'].includes(
      step2Methods.getValues('calculationMethodCode') || '',
    )
  ) {
    return currentSelectionInfos.slice(0, index + 2);
  }

  if (
    (name === 'consumptionTransportTypeCode' ||
      (name === 'radioButton1' && value === 'DK0001')) &&
    step2Methods.getValues('calculationMethodCode') === 'M00019'
  ) {
    return currentSelectionInfos
      .slice(0, index + 3)
      .filter(
        (item) =>
          item.fieldTarget !== 'fuelCode' &&
          !(
            item.fieldTarget === 'fuelEfficiencyCategory' &&
            name !== 'consumptionTransportTypeCode'
          ),
      );
  }

  if (
    name === 'radioButton1' &&
    step2Methods.getValues('calculationMethodCode') === 'M00018'
  ) {
    return currentSelectionInfos.slice(0, index + 3);
  }

  if (
    name === 'consumptionTransportTypeCode' &&
    step2Methods.getValues('calculationMethodCode') === 'M00018'
  ) {
    return currentSelectionInfos.slice(0, index + 4);
  }

  return currentSelectionInfos.slice(0, index + 1);
};

export const createNewParams = (
  name: string,
  value: string,
  newSelectionInfos: SelectionInfo[],
  step1Methods: UseFormReturn<Step1Fields, any, undefined>,
  step2Methods: UseFormReturn<Step2Fields, any, undefined>,
): Record<string, any> => {
  const { targetLciDbYear, targetYearMonth } = step1Methods.getValues();

  const newParams: Record<string, any> = {
    targetLciDbYear,
    targetYearMonth,
    [name]: value,
  };

  newSelectionInfos.forEach((item) => {
    newParams[item.fieldTarget] = step2Methods.getValues(
      item.fieldTarget as any,
    );
  });

  return newParams;
};

export const createMessageDataEntry = (
  scopeCode: string,
  greenHouseCode: string,
) => {
  if (
    scopeCode === '2' ||
    ((scopeCode === '1' || scopeCode === '3') && greenHouseCode === 'CO2')
  ) {
    return true;
  }
};

export const checkInitValueSelectionInfo = (SelectionInfo: SelectionInfo) => {
  const { fieldTarget, data } = SelectionInfo;

  return (
    fieldTarget === 'useOfCertificate' ||
    (fieldTarget === 'greenHouseGasOptionSelected' && data?.length === 1)
  );
};

export const roundToSignificantFigures = (num: string, sigFigs: number) => {
  try {
    let newNum = num;
    const [inte1, dec1] = num.split('.')

    if (inte1.length > sigFigs) {
      const offset = inte1.length - sigFigs
      const x = inte1.split('');
      if (x[sigFigs] === '5') {
        x[sigFigs] = '6'
      }
      return `${Number(`0.${x.join('')}`).toFixed(sigFigs).replace('0.', '')}${'0'.repeat(offset)}`
    }

    if (dec1 === undefined) {
      return inte1
    }

    let count = 0;
    const arr = num.split('')
    for (let i = 0; i < newNum.length; i++) {
      if (count === 0) {
        if (!'0.'.includes(newNum.charAt(i))) count = 1
      } else {
        if (newNum.charAt(i) !== '.')
          count++
      }
      if (count === sigFigs) {
        if (arr[i + 1] === '5') {
          arr[i + 1] = '6'
        }
        newNum = newNum.slice(0, i + 1)
        break
      }
    }

    num = arr.join('')

    const [_, dec2] = newNum.split('.');
    if (dec2 === undefined) {
      newNum = Math.round(Number(num)).toString()
    }

    if (newNum.length < num.length) {
      const [_, dec3] = newNum.split('.');
      newNum = Number(num).toFixed((dec3 ?? '').length)
    }
    return newNum
  } catch (error) {
    return ''
  }
}
