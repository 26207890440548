import { HgbButton } from '@common/atoms/HgbButton';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { AuthenTemplate } from '@layouts/templates';
import * as React from 'react';
import { SpsvCredit } from '@pages/Management/Setting/D08/SpsvCredit';
import { useBooleanState } from '@hooks/useBooleanState';
import { LocalDataClass } from 'data-class/LocalDataClass';
import { useHgbQuery } from 'services/common/query';
import { GetEnterpriseByIdRequest, GetEnterpriseByIdResponse } from 'services/types/enterprise';
import { API_URLS } from '@constants/API_URLS';
import { HgbModal } from '@common/antd/HgbModal';

interface CompaniesUnpaidFeeProps { }

const CompaniesUnpaidFee: React.FC<
  CompaniesUnpaidFeeProps
> = () => {
  const {
    text: { common, A05, D01 },
  } = React.useContext(LanguageContext)!;
  const { user, logout } = React.useContext(AuthContext)!;
  const spsvCreditState = useBooleanState(false);

  const userLocal = LocalDataClass.user;

  const { data } = useHgbQuery<GetEnterpriseByIdResponse, GetEnterpriseByIdRequest>(
    API_URLS.ENTERPRISE_INFO_STATUS + `/${userLocal.enterpriseId}`,
    undefined,
    {
      queryKey: [API_URLS.ENTERPRISE_INFO_STATUS, userLocal.enterpriseId],
    },
  );

  const successModalState = useBooleanState(false);
  const handleCancel = React.useCallback(() => {
    successModalState.turnOff();
  }, []);

  return (
    <>
      <HgbModal
        open={successModalState.value}
        cancelText={common.button.close}
        hgbLogo={true}
        title={D01.modal.enterpriseUpdateCradModalTitle}
        successModal={true}
        onCancel={handleCancel}
      >
        {D01.label.updateCardContent}
      </HgbModal>
      <SpsvCredit
        from='authen'
        modalState={spsvCreditState}
        data={{
          bankCode: userLocal.bankCode ?? '',
          email: data?.result?.picEmail ?? '',
          enterpriseId: userLocal.enterpriseId.toString(),
          kainId: data?.result?.kaiinId ?? '',
          kainPass: data?.result?.kaiinPass ?? ''
        }}
        onSuccess={successModalState.turnOn}
      />
      <AuthenTemplate
        title={
          user.isEnterpriseAdmin
            ? common.label.titleAdminUnpaidFee
            : common.label.titleUserUnpaidFee
        }
      >
        <>
          {user.isEnterpriseAdmin && common.message.messAdminUnpaidFee}

          {user.isEnterpriseUser && common.message.messUserUnpaidFee}

          <div className="tw-flex tw-flex-col tw-gap-16 ">
            <HgbButton
              type="link"
              onClick={logout}
            >
              {A05.pageTitleLogout}
            </HgbButton>
            {user.isEnterpriseAdmin && (
              <HgbButton
                htmlType="submit"
                type="primary"
                onClick={spsvCreditState.turnOn}
              >
                {common.button.cardPayment}
              </HgbButton>
            )}
          </div>
        </>
      </AuthenTemplate>
    </>
  );
};

export { CompaniesUnpaidFee };
