import { HgbButton } from '@common/atoms/HgbButton';
import { LanguageContext } from '@contexts/LanguageContext';
import { AuthenTemplate } from '@layouts/templates';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const AccessRestrictions: React.FC = () => {
  const {
    routs,
    text: { accessRestriction },
  } = useContext(LanguageContext)!;
  const navigate = useNavigate();
  const goToLogin = () => {
    return navigate(-1);
  };

  return (
    <AuthenTemplate title={accessRestriction.title} logo>
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-32">
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-12">
          {accessRestriction.content()}
        </div>
        <HgbButton
          type="primary"
          onClick={goToLogin}
          className="tw-w-full pc:tw-w-220"
        >
          {accessRestriction.button}
        </HgbButton>
      </div>
    </AuthenTemplate>
  );
};
