import { ExclamationCircleOutlined } from '@ant-design/icons';
import HgbModalForm from '@common/antd/HgbModalForm';
import { HgbModalFormBody } from '@common/antd/HgbModalFormBody';
import { HgbModalFormFooter } from '@common/antd/HgbModalFormFooter';
import { HgbModalTitle } from '@common/antd/HgbModalTitle';
import { HgbButton } from '@common/atoms/HgbButton';
import { HgbFieldError } from '@common/contents/HgbFieldError';
import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  HgbSelectOption,
  parseToHgbSelectOptions,
} from '@common/forms';
import {
  HgbTreeSelect,
  HgbTreeSelectOption,
} from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { STATUS_CODE } from '@constants/consts';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { BooleanState } from '@hooks/useBooleanState';
import { useConfirm } from '@hooks/useConfirm';
import { hgbAxiosGetSingle } from '@utils/axios';
import { getIsoDate, validateMonthRange } from '@utils/date.util';
import { debug } from '@utils/debug';
import { calculationFormula, fixNumber } from '@utils/number';
import { convertObjectToTree, getParentKey } from '@utils/object.utils';
import { bigNumberToString } from '@utils/text';
import { Modal } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import {
  useHgbMutationPost,
  useHgbMutationPut,
} from 'services/common/mutation';
import {
  GetSupplierEmissionInputRequest,
  ModifySupplierEmissionInputPayload,
  defaultAddSupplierEmissionInputPayload,
} from 'services/types/emission';
import * as yup from 'yup';
import { SelectionInfo } from '../C01/type';
import { SupplierSubmission } from '../H02/H02';
import { GetSupplierEmissionInputResponse } from './AddSupplierAnswerModal';

interface IEditSupplierAnswerModalProps {
  modalState: BooleanState;
  id?: number;
  status?: string;
  detailData?: GetSupplierEmissionInputResponse;
}

const EditSupplierAnswerModal: React.FC<IEditSupplierAnswerModalProps> = ({
  modalState,
  id,
  status,
  detailData,
}) => {
  const {
    text: { common, H03, E0000, E0101, E0049, E0100, E0091, E0106, E0122 },
    language,
  } = React.useContext(LanguageContext)!;

  const { addMessage } = React.useContext(PortalContext)!;

  const queryClient = useQueryClient();

  const editSupplierAnswerSchema = yup.object({
    enterpriseId: yup.string().required(E0000(H03.groupBuyer)),
    supplierId: yup
      .string()
      .required(E0000(common.columns.supplyChainGroupName)),
    baseId: yup.string().when({
      is: () => responseType !== '1',
      then: (schema) =>
        schema.required(E0000(common.label.organizationBaseName)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    itemId: yup.string().when({
      is: () => responseType === '1',
      then: (schema) =>
        schema.required(E0000(H03.manufacturingCodeProductName)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    targetStartMonth: yup.string().required(E0000(H03.startDate)),
    targetEndMonth: yup.string().required(E0000(H03.endDate)),
    quantity: yup
      .string()
      .typeError(E0000(H03.quantity))
      .when({
        is: () => responseType === '1',
        then: (schema) => schema.required(E0000(H03.quantity)),
        otherwise: (schema) => {
          return schema.notRequired();
        },
      }),
    allocation: yup
      .string()
      .typeError(E0000(H03.allocation))
      .when({
        is: () => responseType !== '1',
        then: (schema) => schema.required(E0000(H03.allocation)),
        otherwise: (schema) => {
          return schema.notRequired();
        },
      })
      .test('validate_value', E0101, (value, schema) => {
        return Number(value) <= 100 || schema.parent.responseType === '1';
      }),
    emissionVolume: yup
      .string()
      .typeError(E0000(common.label.emissionVolume))
      .required(E0000(common.label.emissionVolume))
      .test('', '', (value, context) => {
        if (value.includes('.') && value.split('.')[0].length > 10) {
          return context.createError({
            message: E0122(common.label.emissions),
            path: 'emissionVolume',
          });
        }
        if (!value.includes('.') && value.length > 10) {
          return context.createError({
            message: E0122(common.label.emissions),
            path: 'emissionVolume',
          });
        }
        return true;
      }),
  });

  const { ConfirmModal: ConfirmModalSubmit, hgbConfirm: hgbConfirmSubmit } =
    useConfirm();

  const { ConfirmModal, hgbConfirm } = useConfirm();

  const [err, setErr] = React.useState<string>('');

  const editSupplierAnswerMethods = useForm<ModifySupplierEmissionInputPayload>(
    {
      resolver: yupResolver(editSupplierAnswerSchema),
    },
  );

  const responseType = editSupplierAnswerMethods.watch('responseType');

  const [enterpriseOptions, setEnterpriseOptions] = React.useState<
    HgbSelectOption[]
  >([]);
  const [suplierOptions, setSuplierOptions] = React.useState<HgbSelectOption[]>(
    [],
  );
  const [responseTypeOptions, setResponseTypeOptions] = React.useState<
    HgbSelectOption[]
  >([]);

  const [emissionUnit, setEmissionUnit] = React.useState<string | undefined>(
    H03.correspondingUnit,
  );
  const [quantityUnit, setQuantityUnit] = React.useState<string | undefined>(
    H03.quantityUnit,
  );

  const [orgOptions, setOrgOptions] = React.useState<HgbTreeSelectOption[]>([]);
  const [orgBaseArr, setOrgBaseArr] = React.useState<any>([]);
  const [itemOptions, setItemOptions] = React.useState<HgbSelectOption[]>([]);
  const [supplierResponseUsed, setSupplierResponseUsed] = React.useState<
    number | null | undefined
  >(0);
  const [orgName, setOrgName] = React.useState<string>('');

  React.useEffect(() => {
    editSupplierAnswerMethods.setValue('sentTime', getIsoDate());
  }, [modalState.value]);

  React.useEffect(() => {
    if (id === undefined || !modalState.value) return;
    getDetailSupplierEmission(id);
  }, [modalState.value, id]);

  const getDetailSupplierEmission = async (dataId: number) => {
    if (id === undefined || !modalState.value) return;
    detailData?.nextStepSelectionInfos?.forEach(
      (selectionInfo: SelectionInfo) => {
        switch (selectionInfo.fieldTarget) {
          case 'enterpriseId':
            editSupplierAnswerMethods.setValue(
              selectionInfo.fieldTarget,
              selectionInfo.selectedValue,
            );
            setEnterpriseOptions(parseToHgbSelectOptions(selectionInfo.data));
            break;
          case 'supplierId':
            editSupplierAnswerMethods.setValue(
              selectionInfo.fieldTarget,
              selectionInfo.selectedValue,
            );
            setSuplierOptions(parseToHgbSelectOptions(selectionInfo.data));
            break;

          case 'responseType':
            editSupplierAnswerMethods.setValue(
              selectionInfo.fieldTarget,
              selectionInfo.selectedValue,
            );
            setResponseTypeOptions(parseToHgbSelectOptions(selectionInfo.data));
            break;
          case 'itemId':
            editSupplierAnswerMethods.setValue(
              selectionInfo.fieldTarget,
              selectionInfo.selectedValue,
            );
            setItemOptions(parseToHgbSelectOptions(selectionInfo.data));
            break;
          case 'baseId':
            editSupplierAnswerMethods.setValue(
              selectionInfo.fieldTarget,
              selectionInfo.selectedValue,
            );
            setOrgOptions(
              convertObjectToTree(
                (selectionInfo.dataOrg || []).filter(
                  (item: any) => !getParentKey(item.hierarchicalPath ?? ''),
                ),
                selectionInfo.dataOrg || [],
              ),
            );
            setOrgBaseArr(selectionInfo.dataOrg);
            break;

          case 'targetStartMonth':
          case 'targetEndMonth':
            editSupplierAnswerMethods.setValue(
              selectionInfo.fieldTarget,
              moment(selectionInfo.selectedValue).format('YYYY/MM') || '',
            );
            break;

          default:
            break;
        }
        detailData?.flowInputDetails?.forEach((flowInputDetail: any) => {
          flowInputDetail.inputGroup.forEach((input: any) => {
            switch (input.fieldTarget) {
              case 'allocation':
              case 'quantity':
                editSupplierAnswerMethods.setValue(
                  input.fieldTarget,
                  input.value || '',
                );
                setQuantityUnit(input.unit);
                break;
              case 'responseSummary':
                editSupplierAnswerMethods.setValue(
                  input.fieldTarget,
                  input.value || '',
                );
                break;
              default:
                break;
            }
          });

          switch (flowInputDetail.intensity.fieldTarget) {
            case 'baseVolume':
            case 'emissionIntensity':
              editSupplierAnswerMethods.setValue(
                flowInputDetail.intensity.fieldTarget,
                bigNumberToString(flowInputDetail.intensity.value),
              );
              setEmissionUnit(flowInputDetail.intensity.unit);
              break;
            default:
              break;
          }
        });
      },
    );
    setSupplierResponseUsed(detailData?.supplierResponseUsed);
    editSupplierAnswerMethods.setValue(
      'emissionVolume',
      bigNumberToString(detailData?.flowResultItem?.value),
    );
  };

  const editSupplierAnswerMutate = useHgbMutationPut<
    unknown,
    ModifySupplierEmissionInputPayload
  >(API_URLS.SUPPLIER_CHAIN_RESPONSE_UPDATE, {});

  const validationPayloadMutation = useHgbMutationPost<
    unknown,
    ModifySupplierEmissionInputPayload
  >(API_URLS.CHECK_SUPPLIER_RESPONSE, {
    isAlert: false,
  });

  const handleSubmit = editSupplierAnswerMethods.handleSubmit(
    async (data) => {
      const value = validateMonthRange(
        moment(data.targetStartMonth, 'YYYYMM').format('YYYY/MM') || '',
        moment(data.targetEndMonth, 'YYYYMM').format('YYYY/MM') || '',
      );

      if (value !== 'PASS') {
        if (value === 'ORDER') {
          setErr(E0049);
        } else setErr(E0100);
        return;
      }
      setErr('');
      if (id === undefined) return;
      const response = await hgbAxiosGetSingle<SupplierSubmission>(
        API_URLS.SUPPLIER_SUPPLIER_RESPONSE,
        id,
      );
      if (response?.status?.value !== status) {
        addMessage('error', E0091);
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
        });
        return;
      }
      const hierarchicalPath =
        responseType === '2'
          ? orgBaseArr.filter((item: any) => item.value === data.baseId)[0]
              .hierarchicalPath
          : '';
      setOrgName(
        responseType === '2'
          ? orgBaseArr.filter((item: any) => item.value === data.baseId)[0].name
          : '',
      );
      const payload = {
        ...data,
        id: id,
        hierarchicalPath: hierarchicalPath,
        targetStartMonth: data.targetStartMonth?.replace('/', ''),
        targetEndMonth: data.targetEndMonth?.replace('/', ''),
        supplierResponseIdCheck: id,
      };
      let result: boolean = false;
      validationPayloadMutation.mutateAsync(payload).then(async (data) => {
        if (data.statusCode === STATUS_CODE.success) {
          result = await hgbConfirmSubmit();
        } else if (data.statusCode === STATUS_CODE.orgBaseAllocation) {
          result = await hgbConfirm();
        } else {
          modalState.turnOff();
          addMessage('error', data.message ?? '');
          return;
        }
        result &&
          editSupplierAnswerMutate.mutateAsync(payload).then(() => {
            handleSuccess();
          });
      });
    },
    (error) => {
      debug.log(error);
    },
  );

  const handleSubmitDebounce = debounce(handleSubmit, 500);

  const handleSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
    });
    modalState.turnOff();
  };

  React.useEffect(() => {
    let emissionVolume = calculationFormula(
      responseType,
      editSupplierAnswerMethods.watch('allocation'),
      editSupplierAnswerMethods.watch('quantity'),
      editSupplierAnswerMethods.watch('emissionIntensity'),
      editSupplierAnswerMethods.watch('baseVolume'),
    );
    let [inte, dec] = emissionVolume.split('.');
    let decNumber = 0;
    if (dec !== undefined) {
      decNumber = 16 - inte?.length - 1;
    }
    editSupplierAnswerMethods.setValue(
      'emissionVolume',
      fixNumber(emissionVolume, decNumber),
    );
  }, [
    responseType,
    editSupplierAnswerMethods.watch('quantity'),
    editSupplierAnswerMethods.watch('allocation'),
    editSupplierAnswerMethods.watch('baseVolume'),
    editSupplierAnswerMethods.watch('emissionIntensity'),
  ]);

  const supplierEmissionMutation = useHgbMutationPost<
    GetSupplierEmissionInputResponse,
    GetSupplierEmissionInputRequest | null
  >(API_URLS.SUPPLIER_CHAIN_RESPONSE_FLOW, {
    isAlert: false,
  });

  /* watch org or item*/
  const changeValue = () => {
    supplierEmissionMutation
      .mutateAsync({
        enterpriseId: editSupplierAnswerMethods.watch('enterpriseId'),
        supplierId: editSupplierAnswerMethods.watch('supplierId'),
        responseType: editSupplierAnswerMethods.watch('responseType'),
        itemId: editSupplierAnswerMethods.watch('itemId'),
        baseId: editSupplierAnswerMethods.watch('baseId'),
        targetStartMonth: editSupplierAnswerMethods
          .watch('targetStartMonth')
          ?.replace('/', ''),
        targetEndMonth: editSupplierAnswerMethods
          .watch('targetEndMonth')
          ?.replace('/', ''),
      })
      .then((rs) => {
        rs.result?.flowInputDetails?.forEach((flowInputDetail: any) => {
          flowInputDetail.inputGroup.forEach((input: any) => {
            switch (input.fieldTarget) {
              case 'allocation':
              case 'quantity':
                editSupplierAnswerMethods.setValue(
                  input.fieldTarget,
                  input.value || '',
                );
                setQuantityUnit(input.unit);
                break;
              case 'responseSummary':
                editSupplierAnswerMethods.setValue(
                  input.fieldTarget,
                  input.value || '',
                );
                break;
              default:
                break;
            }
          });

          switch (flowInputDetail.intensity.fieldTarget) {
            case 'baseVolume':
            case 'emissionIntensity':
              editSupplierAnswerMethods.setValue(
                flowInputDetail.intensity.fieldTarget,
                bigNumberToString(flowInputDetail.intensity.value),
              );
              setEmissionUnit(flowInputDetail.intensity.unit);
              break;
            default:
              break;
          }
        });
        setSupplierResponseUsed(rs.result?.supplierResponseUsed);
      });
  };

  return (
    <>
      <FormProvider {...editSupplierAnswerMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={<HgbModalTitle className="">{H03.titleEdit}</HgbModalTitle>}
          open={modalState.value}
          onCancel={modalState.turnOff}
          footer={null}
          closable={true}
          maskClosable={false}
          destroyOnClose={true}
          afterClose={() => {
            editSupplierAnswerMethods.reset(
              defaultAddSupplierEmissionInputPayload,
            );
            setEnterpriseOptions([]);
            setItemOptions([]);
            setOrgOptions([]);
            setSuplierOptions([]);
            setOrgBaseArr([]);
            setErr('');
          }}
        >
          <HgbModalForm onSubmit={handleSubmitDebounce}>
            <HgbModalFormBody>
              {/* blank */}
              <HgbSelect
                label={H03.groupBuyer}
                {...editSupplierAnswerMethods.register('enterpriseId')}
                options={enterpriseOptions}
                required
                disabled
              />
              {/* blank */}
              <HgbSelect
                label={common.columns.supplyChainGroupName}
                {...editSupplierAnswerMethods.register('supplierId')}
                options={suplierOptions}
                required
                disabled
              />
              {/* blank */}
              <HgbSelect
                label={H03.responseType}
                {...editSupplierAnswerMethods.register('responseType')}
                options={responseTypeOptions}
                required
                infoTooltip={H03.responseTooltip}
                palcement={language === 'jp' ? 'topLeft' : 'topRight'}
                disabled
              />
              {/* org */}
              {responseType === '2' ? (
                <HgbTreeSelect
                  label={common.label.organizationBaseName}
                  {...editSupplierAnswerMethods.register('baseId')}
                  treeData={orgOptions}
                  required
                  showSearch
                  onChangeValue={() => changeValue()}
                />
              ) : null}
              {/* blank */}
              <div>
                <div className="tw-flex tw-justify-between tw-gap-36">
                  <HgbDatePicker
                    label={H03.startDate}
                    picker="month"
                    {...editSupplierAnswerMethods.register('targetStartMonth')}
                    required
                    infoTooltip={H03.startDateTooltip}
                    palcement="topLeft"
                    allowClear
                    format="YYYY/MM"
                    onChangeValue={() => changeValue()}
                  />
                  <HgbDatePicker
                    label={H03.endDate}
                    picker="month"
                    {...editSupplierAnswerMethods.register('targetEndMonth')}
                    required
                    infoTooltip={H03.endDateTooltip}
                    palcement="topRight"
                    allowClear
                    format="YYYY/MM"
                    onChangeValue={() => changeValue()}
                  />
                </div>
                {err ? <HgbFieldError>{err}</HgbFieldError> : null}
              </div>
              {/* product */}
              {responseType === '1' ? (
                <>
                  <HgbSelect
                    label={H03.manufacturingCodeProductName}
                    {...editSupplierAnswerMethods.register('itemId')}
                    options={itemOptions}
                    required
                    allowClear
                    showSearch
                    infoTooltip={H03.manufacturingCodeProductNameTootip}
                    palcement={language === 'jp' ? 'topLeft' : 'topRight'}
                    onChangeValue={() => changeValue()}
                  />
                  <HgbAntdInput
                    label={H03.quantity}
                    {...editSupplierAnswerMethods.register('quantity')}
                    maxLength={13}
                    required
                    type="positiveDecimal"
                    suffix={quantityUnit}
                    forceMaxDecimal={3}
                  />
                  <HgbAntdInput
                    label={common.label.emissionIntensity}
                    {...editSupplierAnswerMethods.register('emissionIntensity')}
                    required
                    readOnly
                    suffix={emissionUnit}
                  />
                </>
              ) : null}

              {/* org */}
              {responseType === '2' ? (
                <>
                  <HgbAntdInput
                    label={H03.scopeHalfEmissions}
                    {...editSupplierAnswerMethods.register('baseVolume')}
                    required
                    readOnly
                    suffix={'tCO2'}
                  />
                  <HgbAntdInput
                    label={H03.allocation}
                    {...editSupplierAnswerMethods.register('allocation')}
                    required
                    maxLength={4}
                    infoTooltip={H03.allocationTooltip}
                    palcement="topLeft"
                    type={'positiveDecimal'}
                    suffix={'%'}
                  />
                </>
              ) : null}

              {/* blank */}
              <HgbAntdInput
                label={common.label.emissionVolume}
                {...editSupplierAnswerMethods.register('emissionVolume')}
                readOnly
                required
                suffix={'tCO2'}
              />
              {/* blank */}
              <HgbAntdInput
                label={H03.responseSummary}
                {...editSupplierAnswerMethods.register('responseSummary')}
                type="text"
              />
            </HgbModalFormBody>
            <HgbModalFormFooter>
              <HgbButton className="tw-min-w-100" onClick={modalState.turnOff}>
                {common.button.cancel}
              </HgbButton>
              <HgbButton
                type="primary"
                className="tw-min-w-100"
                htmlType="submit"
                loading={validationPayloadMutation.isLoading}
              >
                {status === 'RETURNED'
                  ? common.button.changeAndResend
                  : common.button.change}
              </HgbButton>
            </HgbModalFormFooter>
          </HgbModalForm>
        </Modal>
      </FormProvider>
      <ConfirmModalSubmit
        title={H03.modalEdit.title}
        subTitle={H03.modalEdit.subTitle}
        okText={common.button.change}
        cancelText={common.button.cancel}
        className="tw-grid tw-grid-cols-[minmax(100px,_auto),_1fr] [&_p]:tw-font-regular"
      ></ConfirmModalSubmit>
      <ConfirmModal
        title={
          <ExclamationCircleOutlined className="tw-text-[50px] tw-text-[#fd7e14]" />
        }
        okText={common.button.confirm}
        cancelText={common.button.cancel}
        className="tw-font-regular"
      >
        {H03.modalSubmit.content(
          orgName,
          supplierResponseUsed as number,
          editSupplierAnswerMethods.getValues('targetStartMonth') || '',
          editSupplierAnswerMethods.getValues('targetEndMonth') || '',
        )}
      </ConfirmModal>
    </>
  );
};

export { EditSupplierAnswerModal };
