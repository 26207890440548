import { emptyToString } from '@utils/text';
import postal from 'japan-postal-code-oasis';
import { useEffect, useState } from 'react';
postal.configure('/postalCode/');
// postal.configure('../node_modules/japan-postal-code-oasis/zipdata');

export type AddressType = {
  error?: string;
  arch: {
    prefecture: string;
    city: string;
    area: string;
    street: string;
    full: string;
  };
};

const addressDefault: AddressType = {
  error: undefined,
  arch: {
    prefecture: '',
    city: '',
    area: '',
    street: '',
    full: '',
  },
};

export const useAddress = (postalCode: string, errorMessage = '_') => {
  const [address, setAddress] = useState<AddressType>(addressDefault);
  useEffect(() => {
    if(postalCode === '8693212'){
      setAddress({
        error: undefined,
        arch: {
          prefecture:  '43',
          city: '宇城市',
          area: '三角町手場',
          street: '５９７－１',
          full: '熊本県宇城市三角町手場５９７－１',
        }
      })
      return;
    }
    if (
      postalCode?.toString().indexOf('000') === 0 &&
      postalCode?.length === 7
    ) {
      setAddress({
        ...addressDefault,
        error: errorMessage,
      });
      return;
    }
    postal(postalCode)
      .then((addr: any) => {
        if (
          (
            emptyToString(addr?.prefecture) +
            emptyToString(addr?.city) +
            emptyToString(addr?.area) +
            emptyToString(addr?.street)
          ).trim() === ''
        ) {
          setAddress({
            ...addressDefault,
            error:
              (postalCode ?? '').toString().length === 7
                ? errorMessage
                : undefined,
          });
        } else {
          setAddress({
            error: undefined,
            arch: {
              prefecture: emptyToString(addr?.prefecture),
              city: emptyToString(addr?.city),
              area: emptyToString(addr?.area),
              street: emptyToString(addr?.street),
              full:
                emptyToString(addr?.prefecture) +
                emptyToString(addr?.city) +
                emptyToString(addr?.area) +
                emptyToString(addr?.street),
            },
          });
        }
      })
      .catch((err: any) => {
        setAddress({
          ...addressDefault,
          error:
            (postalCode ?? '').toString().length === 7
              ? errorMessage
              : undefined,
        });
      });
  }, [postalCode, errorMessage]);

  return [address];
};
