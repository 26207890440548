import axios, { AxiosHeaders, AxiosResponse } from 'axios';
import { LocalDataClass } from 'data-class/LocalDataClass';
import { LocalStorageDataClass } from 'data-class/LocalStorageDataClass';
import { ResponseType } from 'types';
import { API_URLS } from '../constants/API_URLS';
import {
  REACT_APP_BASE_URL,
  SESSION_TIME,
  STATUS_CODE,
} from '../constants/consts';
import { debug } from './debug';

let preventRefreshToken = false;
let preventRefreshTokenTimer: any = undefined;
const resetPrevent = () => {
  preventRefreshToken = false;
  if (preventRefreshTokenTimer) clearTimeout(preventRefreshTokenTimer);
};

let sessionTimer: any = undefined;
const timeOutEventHandler = () => {
  const user = LocalDataClass.user;
  user.authenStatus = 'SESSION_TIMEOUT';
  LocalDataClass.user = user;
};

export type HgbAxiosError = {
  code?: string;
  message?: string;
  name?: string;
  response?: any;
};

export const hgbAxios = (
  type?: 'download' | 'upload' | 'blob',
  replaceURL?: string,
) => {
  const refreshToken = async (isBypass: boolean) => {
    return new Promise((resolve, reject) => {
      const user = LocalDataClass.user;
      delete axios.defaults.headers.common.Authorization;
      axiosInstance
        .post(API_URLS.TOKEN_REFRESH, {
          accountId: user.accountId,
          refreshToken: user.refreshToken,
          signature: '',
        })
        .then((response) => {
          const result = response?.data?.result;
          LocalDataClass.user = {
            ...user,
            profileToken: result?.profileToken,
            refreshToken: result?.refreshToken,
          };
          resolve(result?.profileToken);
          preventRefreshToken = true;
          if (preventRefreshTokenTimer) clearTimeout(preventRefreshTokenTimer);
          preventRefreshTokenTimer = setTimeout(resetPrevent, 10000);
        })
        .catch(async (err) => {
          if (preventRefreshToken || isBypass) {
            resolve(LocalDataClass.user.profileToken);
            return;
          }
          timeOutEventHandler();
          await new Promise((resolve) => setTimeout(resolve, 1000));
        });
    });
  };

  const axiosInstance = axios.create();
  axiosInstance.defaults.baseURL = REACT_APP_BASE_URL;
  if (replaceURL) {
    axiosInstance.defaults.baseURL = replaceURL;
  }

  const runInterceptor = () => {
    axiosInstance.interceptors.request.use(
      async (config) => {
        const user = LocalDataClass.user;
        config.headers.Accept = '*/*';
        if (type === 'blob') {
          config.headers['Content-Type'] = 'blob';
          config.responseType = 'blob';
        }
        if (type === 'download') {
          config.headers['Content-Type'] = 'blob';
          config.responseType = 'arraybuffer';
        }
        (config.headers as AxiosHeaders).set(
          'X-XSRF-TOKEN',
          LocalDataClass['X-XSRF-TOKEN'],
        );
        if (
          config.url?.includes('reissue') ||
          config.url?.includes('logout') ||
          config.url?.includes('login') ||
          config.url?.includes('bank-name') ||
          config.url?.includes('bank-area') ||
          config.url?.includes('temporarily-register') ||
          config.url?.includes('method-type') ||
          config.url?.includes('have-none') ||
          config.url?.includes('check-temporarily-register-expiration') ||
          config.url?.includes('bank-private-policy-url') ||
          config.url?.includes('enterprise-landing-page') ||
          config.url?.includes('presiding-bank') ||
          config.url?.includes('insert-card') ||
          config.url?.includes('four-mem-add') ||
          config.url?.includes('merchant-information') ||
          config.url?.includes('send-email-bank-complimentary') ||
          config.url?.includes('introducer-name') ||
          config.url?.includes('terms')
        ) {
          delete config?.headers?.Authorization;
        } else {
          (config.headers as AxiosHeaders).set(
            'Authorization',
            `Bearer ${user.profileToken}`,
          );
        }

        if (
          config.data &&
          !config.url?.includes('ocr') &&
          !config.url?.includes('upload-csv') &&
          !config.url?.includes('lci-db')
        ) {
          const requestTimestamp = new Date().getTime();
          config.data = { ...config.data, requestTimestamp };
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    axiosInstance.interceptors.response.use(
      async (response: AxiosResponse<ResponseType>) => {
        clearTimeout(sessionTimer);
        const userLocal = LocalDataClass.user;
        if (userLocal.authenStatus !== 'LOGIN') {
          sessionTimer = setTimeout(timeOutEventHandler, SESSION_TIME);
        }
        userLocal.isMaintenance = false;
        LocalDataClass.user = userLocal;

        return response;
      },
      async (error: any) => {
        try {
          const data = await fetch(
            axiosInstance.defaults.baseURL + '/actuator/info',
          );
          if (!data?.ok) {
            if (sessionTimer) {
              clearTimeout(sessionTimer);
            }
            const userLocal = LocalDataClass.user;
            userLocal.isMaintenance = true;
            userLocal.isLocked = false;
            LocalDataClass.user = userLocal;
          }
        } catch (error) {
          if (sessionTimer) {
            clearTimeout(sessionTimer);
          }
          const userLocal = LocalDataClass.user;
          userLocal.isMaintenance = true;
          userLocal.isLocked = false;
          LocalDataClass.user = userLocal;
        }
        const statusCode = error?.response?.data?.statusCode;
        const isBypassRefreshToken = (
          error?.request?.responseURL || ''
        ).includes('csrf-token');
        if (statusCode === STATUS_CODE.tokenExpired) {
          return refreshToken(isBypassRefreshToken)
            .then((newToken) => {
              axios.defaults.headers.common.Authorization =
                'Bearer ' + newToken;
              error?.config?.headers?.set(
                'Authorization',
                `Bearer ${newToken}`,
              );
              return axiosInstance.request(error.config);
            })
            .catch((err) => {
              debug.error(err);
            });
        } else {
          const statusCode = (error as any)?.response?.data?.statusCode;
          if (statusCode === STATUS_CODE.paymentIssue) {
            const userLocal = LocalDataClass.user;
            userLocal.isLocked = true;
            LocalDataClass.user = userLocal;
          }
          if (statusCode === STATUS_CODE.logoutInvalidIP) {
            const userLocal = LocalDataClass.user;
            userLocal.authenStatus = 'LOGOUT';
            LocalDataClass.user = userLocal;
          }
          return Promise.reject(error);
        }
      },
    );
  };
  runInterceptor();
  window.addEventListener('load', () => {
    runInterceptor();
  });
  window.addEventListener(
    LocalStorageDataClass.changeEventName('X-XSRF-TOKEN'),
    runInterceptor,
  );
  return axiosInstance;
};

export const hgbAxiosGetSingle = async <T,>(
  url: string,
  id: number | string,
  request?: any,
): Promise<T | any> => {
  const query = Object.entries(request ?? {})
    .filter(([_, value]) => (value?.toString().trim() ?? '') !== '')
    .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`)
    .join('&');
  try {
    return (
      query
        ? await hgbAxios().get(
            `${url}/${id}?lang=${LocalDataClass.language}&${query}`,
          )
        : await hgbAxios().get(`${url}/${id}?lang=${LocalDataClass.language}`)
    )?.data?.result as T;
  } catch (error) {
    return error;
  }
};
