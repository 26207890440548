import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  HgbSelectOption,
} from '@common/forms';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { MONTH_OF_YEAR_OPTIONS } from '@constants/options';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { BooleanState, useBooleanState } from '@hooks/useBooleanState';
import { debug } from '@utils/debug';
import { createMajorOptions } from '@utils/mapFixedDataD01';
import { Modal } from 'antd';
import * as React from 'react';
import { FormProvider } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHgbMutationPut } from 'services/common/mutation';
import { Enterprise } from 'services/types/enterprise';
import { codeMajors } from './data';
import { isEmpty } from 'lodash';
import { HgbModal } from '@common/antd/HgbModal';
import { HgbModalTitle } from '@common/antd/HgbModalTitle';
import HgbModalForm from '@common/antd/HgbModalForm';
import { HgbModalFormBody } from '@common/antd/HgbModalFormBody';
import { HgbModalFormFooter } from '@common/antd/HgbModalFormFooter';
import { HgbButton } from '@common/atoms/HgbButton';
import { HgbRadioGroup2 } from '@common/antd/HgbRadioGroup2';
import { useEnterprise } from './useEnterprise';

interface ResumtionCorporateUsageModalProps {
  modalState: BooleanState;
  data: Enterprise;
  haveNoneOption: HgbSelectOption[];
  picIdOption: HgbSelectOption[];
  categoryCodeOptions: HgbSelectOption[];
  codeMediumOptions: HgbSelectOption[];
  paymentOption: HgbSelectOption[];
}

type ResumtionCorporateUsage = {};

const D29: React.FunctionComponent<ResumtionCorporateUsageModalProps> = ({
  modalState,
  data,
  haveNoneOption,
  picIdOption,
  categoryCodeOptions,
  codeMediumOptions,
  paymentOption,
}) => {
  const {
    text: { common, D29, D01 },
  } = React.useContext(LanguageContext)!;
  const queryClient = useQueryClient();
  const { user } = React.useContext(AuthContext)!;
  const codeMajorOptions = createMajorOptions(codeMajors);
  const confirmModal = useBooleanState(false);
  const successModal = useBooleanState(false);
  const [billingStartDate, setBillingStartDate] = React.useState<string>('');
  const [companyName, setCompanyName] = React.useState<string>('');
  const { updateMethods } = useEnterprise(data);

  React.useEffect(() => {
    updateMethods.setValue(
      'availableScope3CategoryArray',
      data?.availableScope3Category?.length > 0
        ? data?.availableScope3Category?.split(',')
        : [],
    );
  }, [modalState, data?.availableScope3Category]);

  const resumtionCorporateUsageMutate = useHgbMutationPut<unknown, Enterprise>(
    API_URLS.SUPPLIER_PRODUCT,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
        });
        successModal.turnOn();
      },
      onError: () => {
      },
    },
  );

  const handleSubmit = updateMethods.handleSubmit(
    (data, e) => {
      setCompanyName(data?.enterpriseName);
      setBillingStartDate(data?.billingStartDate);
      // resumtionCorporateUsageMutate.mutate({});
      confirmModal.turnOn();
    },
    (error) => {
      debug.error(error);
    },
  );

  const handeSubmitSuuccess = () => {
    confirmModal.turnOff();
    successModal.turnOn();
  };

  return (
    <>
      <FormProvider {...updateMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={<HgbModalTitle>{D29.title}</HgbModalTitle>}
          open={modalState.value}
          onCancel={modalState.turnOff}
          footer={null}
          afterClose={() => updateMethods.reset(data)}
          closable={true}
          maskClosable={false}
          destroyOnClose
        >
          <HgbModalForm onSubmit={handleSubmit}>
            <HgbModalFormBody>
              <div className="tw-space-y-16">
                <HgbAntdInput
                  disabled={true}
                  {...updateMethods.register('enterpriseCode')}
                  maxLength={100}
                  type={'K'}
                  label={common.label.enterpriseCode}
                  placeholder={D01.placeholder.enterpriseCode}
                />
                <HgbAntdInput
                  {...updateMethods.register('enterpriseName')}
                  maxLength={100}
                  type={'K'}
                  required
                  label={common.label.enterpriseName}
                  placeholder={common.placeholder.enterpriseName}
                />
                <HgbAntdInput
                  {...updateMethods.register('enterpriseNameKana')}
                  maxLength={100}
                  type={'K'}
                  required
                  label={common.label.enterpriseNameKana}
                  placeholder={common.placeholder.enterpriseNameKana}
                />
                <HgbAntdInput
                  {...updateMethods.register('representative')}
                  maxLength={50}
                  type={'K'}
                  required
                  label={common.label.representative}
                  placeholder={D01.placeholder.representative}
                />
                <HgbAntdInput
                  {...updateMethods.register('representativeKana')}
                  maxLength={50}
                  type={'K'}
                  required
                  label={common.label.representativeKana}
                  placeholder={D01.placeholder.representativeKana}
                />
                <HgbAntdInput
                  {...updateMethods.register('postalCode')}
                  maxLength={7}
                  type={'integer'}
                  required
                  label={common.label.postalCode}
                  placeholder={common.placeholder.postalCode}
                />
                <HgbAntdInput
                  {...updateMethods.register('address1')}
                  maxLength={100}
                  type={'K'}
                  required
                  label={common.label.fullAddress}
                  placeholder={D01.placeholder.address1}
                />
                <HgbAntdInput
                  {...updateMethods.register('address2')}
                  maxLength={100}
                  type={'K'}
                  label={common.label.address2}
                  placeholder={D01.placeholder.address2}
                />
                <HgbAntdInput
                  {...updateMethods.register('phoneNumber')}
                  maxLength={11}
                  type={'positiveInteger'}
                  label={common.label.phoneNumber}
                  placeholder={common.placeholder.phoneNumber}
                />

                <HgbSelect
                  {...updateMethods.register('startMonthOfYear')}
                  options={MONTH_OF_YEAR_OPTIONS}
                  label={common.label.startMonthOfYear}
                  placeholder={D01.placeholder.startMonthOfYear}
                  required
                />
                <HgbSelect
                  label={common.label.industryMajorCodeClassification}
                  className="tw-self-start"
                  {...updateMethods.register('industryCodeMajorClassification')}
                  placeholder={''}
                  options={codeMajorOptions}
                  required
                />
                <HgbSelect
                  label={common.label.industryMediumCodeClassification}
                  className="tw-self-start"
                  {...updateMethods.register(
                    'industryCodeMediumClassification',
                  )}
                  placeholder={''}
                  options={codeMediumOptions}
                  required
                />
                <HgbDatePicker
                  format="YYYY/MM"
                  required={user.subscriptionType !== 'COMPLIMENTARY'}
                  {...updateMethods.register('billingStartDate')}
                  label={common.label.billingDate}
                  placeholder={D01.placeholder.billingDate}
                  disabledDate
                  allowClear={user.subscriptionType === 'COMPLIMENTARY'}
                />
                {user.subscriptionType !== 'COMPLIMENTARY' && (
                  <HgbSelect
                    {...updateMethods.register('paymentType')}
                    autoInitValue={!isEmpty(data?.paymentType) ? true : false}
                    options={paymentOption}
                    label={common.label.paymentMethod}
                    required={user.subscriptionType !== 'COMPLIMENTARY'}
                    placeholder={''}
                    screenType={'C01'}
                  />
                )}
                <HgbRadioGroup2
                  name={'hasAccount'}
                  options={haveNoneOption}
                  label={common.label.isCorporateAccount}
                  required
                  optionsClassName="tw-w-180"
                />
                {data?.hasAccount === '1' && (
                  <HgbAntdInput
                    {...updateMethods.register('branchNumber')}
                    maxLength={3}
                    type={'integer'}
                    required
                    label={common.label.branchNumber}
                  />
                )}
                {data?.hasAccount === '1' && (
                  <HgbAntdInput
                    {...updateMethods.register('corporateAccountNumber')}
                    maxLength={7}
                    type={'integer'}
                    required
                    label={common.label.corporateAccountNumber}
                  />
                )}
                <HgbAntdInput
                  {...updateMethods.register('cif')}
                  maxLength={15}
                  type={'unicodeWithoutSpecicalCharacter'}
                  label={D01.label.cif}
                />
                <HgbRadioGroup2
                  name={'isInvested'}
                  options={haveNoneOption}
                  label={D01.label.isInvested}
                  required
                  optionsClassName="tw-w-180"
                />
                {data?.groupStatus === 'ACTIVE' &&
                  !isEmpty(data?.id?.toString()) && (
                    <HgbSelect
                      label={common.label.picName}
                      {...updateMethods.register('picId')}
                      placeholder={''}
                      options={picIdOption}
                    />
                  )}
                <HgbAntdInput
                  disabled={true}
                  {...updateMethods.register('picEmail')}
                  label={common.label.picEmail}
                  type="H"
                  maxLength={100}
                  required
                />

                <HgbSelect
                  label={D01.label.scope3DirectInput}
                  className="tw-self-start"
                  {...updateMethods.register('availableScope3CategoryArray')}
                  placeholder={''}
                  options={categoryCodeOptions}
                  mode="multiple"
                  required
                />
              </div>
            </HgbModalFormBody>
            <HgbModalFormFooter>
              <HgbButton className="tw-min-w-100" onClick={modalState.turnOff}>
                {common.button.cancel}
              </HgbButton>
              <HgbButton
                type="primary"
                className="tw-min-w-100"
                htmlType="submit"
              >
                {D29.restartBtn}
              </HgbButton>
            </HgbModalFormFooter>
          </HgbModalForm>
        </Modal>
      </FormProvider>
      <HgbModal
        open={confirmModal.value}
        cancelText={common.button.cancel}
        hgbLogo
        title={D29.titleConfirmModal}
        onCancel={confirmModal.turnOff}
        okText={common.button.approve}
        onOk={handeSubmitSuuccess}
      >
        {D29.contentConfirmModalComplimantory(companyName)}
      </HgbModal>
      <HgbModal
        open={successModal.value}
        cancelText={common.button.close}
        hgbLogo
        title={D29.titleSuccessModal}
        successModal
        onCancel={successModal.turnOff}
        className="tw-text-center"
      >
        {D29.contentSuccessModal}
      </HgbModal>
    </>
  );
};

export default D29;
